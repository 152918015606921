import React from 'react';
import { ListItemText, ListItem, Checkbox } from '@mui/material';
import { ListChildComponentProps } from 'react-window';
import { Concept } from '../../../reduxState/store/concept/types';
import { selectedConceptsType } from '../ConceptManager';
import './Concept.scss';

interface ConceptProps extends ListChildComponentProps {
  onSelect: (concept: Concept) => void;
  selectedConcepts: selectedConceptsType;
}

const ConceptComponent = ({ index, style, data, selectedConcepts, onSelect }: ConceptProps): JSX.Element => {
  const concept = data[index] as Concept;
  const isSelected = selectedConcepts[concept.ID] !== undefined;

  return (
    <ListItem style={style} className={`concept ${index % 2 ? 'odd-item' : 'even-item'}`} key={index}>
      <Checkbox checked={isSelected} onChange={() => onSelect(concept)} />
      <ListItemText primary={concept.Value} />
    </ListItem>
  );
};

export default ConceptComponent;
