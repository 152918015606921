import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Visibility, VisibilityOff, Check, Warning } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from '@mui/material';
import { CircularProgress, IconButton, InputAdornment, MenuItem, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { inviteUserToApplication } from 'api/user';
import Button from 'components/Button/Button';
import TextField from 'components/TextField/TextField';
import UsersTable from 'components/UsersTable/UsersTable';
import { parseVersion, findRtkErrorMessage } from 'helpers';
import { userRolesSelector } from 'helpers/auth0';
import { useGetApplicationCallbackQuery, useUpdateApplicationCallbackMutation } from 'reduxState/store/application/api';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import { Application as ApplicationType } from '../../../reduxState/store/user/types';
import './ApplicationSetting.scss';

const versions = ['V2', 'V3', 'V4'];

const ApplicationSetting = ({
  applicationId,
  applications,
}: {
  applicationId: string;
  applications: ApplicationType[];
}): JSX.Element => {
  const [callbackUrl, setCallbackUrl] = useState('');
  const [callbackKey, setCallbackKey] = useState('');
  const [callbackVersion, setCallbackVersion] = useState('');
  const [inviteUserEmail, setInviteUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showKey, setShowKey] = useState(false);
  const [userInviteStatus, setUserInviteStatus] = useState<'success' | 'failed' | null>(null);
  const [showAddUserInput, setShowAddUserInput] = useState(false);

  const { user } = useAuth0();

  const isOwner = userRolesSelector(user).includes('owner');
  const application = applications.find((app: ApplicationType) => app.id === applicationId) || ({} as ApplicationType);

  const { data: deviceData } = useGetDeviceQuery(applicationId);
  const deviceToken = deviceData?.DeviceToken;

  const { data: appCallbackData, error: appCallbackError } = useGetApplicationCallbackQuery({ appId: applicationId });
  const [
    updateApplicationCallback,
    { isLoading: isUpdatingAppCallback, error: appCallbackUpdateError },
  ] = useUpdateApplicationCallbackMutation();

  const addUserIcon = showAddUserInput ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

  const getSettings = useCallback(() => {
    if (appCallbackData) {
      setCallbackKey(appCallbackData.CallbackKey);
      setCallbackUrl(appCallbackData.CallbackURL);
      setCallbackVersion(parseVersion(appCallbackData.CallbackVersion));
    } else if (appCallbackError) {
      toast.error('Failed to retrieve application callback.');
      console.error(appCallbackError);
    }
    setIsLoading(false);
  }, [appCallbackData]);

  const updateCallback = async (): Promise<void> => {
    const body = {
      CallbackURL: callbackUrl,
      CallbackKey: callbackKey,
      CallbackVersion: callbackVersion,
    };

    updateApplicationCallback({
      appId: applicationId,
      body,
    });
  };

  useEffect((): void => {
    getSettings();
  }, [appCallbackData]);

  const onCreateUser = async () => {
    const roleName = application?.partnerId;

    if (!roleName || !deviceToken) return;

    const response = await inviteUserToApplication(applicationId, deviceToken, inviteUserEmail, roleName);

    setUserInviteStatus(response?.ok ? 'success' : 'failed');
  };

  return (
    <div className="application-setting">
      <>
        <TextField
          variant="outlined"
          customLabel={
            <div className="application-setting-custom-label-container">
              <span>Callback URL</span>
              <Link
                href="https://kb.wildfire-corp.com/article/kfgi-syncing-commission-data-via-api-and-callbacks"
                target="_blank"
              >
                <InfoIcon />
              </Link>
            </div>
          }
          disabled={isLoading}
          value={callbackUrl}
          onChange={e => setCallbackUrl(e.target.value)}
          error={!callbackUrl}
          inputProps={{ 'aria-label': 'Callback URL' }}
          helperText={!callbackUrl ? 'Callback URL required' : ''}
        />
        <TextField
          label="Callback Key"
          variant="outlined"
          disabled={isLoading}
          value={callbackKey}
          onChange={e => setCallbackKey(e.target.value)}
          type={showKey ? 'text' : 'password'}
          inputProps={{ 'aria-label': 'Callback Key' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowKey(!showKey)} size="large">
                  {showKey ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!callbackKey}
          helperText={!callbackKey ? 'Callback Key required' : ''}
        />
        <TextField
          variant="outlined"
          customLabel={
            <div className="application-setting-custom-label-container">
              <span>Callback Version</span>
              <Tooltip title="Ensure the callback version you select matches the commission API version" arrow>
                <InfoIcon style={{ color: '#3f51b5' }} />
              </Tooltip>
            </div>
          }
          disabled={isLoading}
          value={callbackVersion}
          onChange={e => setCallbackVersion(e.target.value)}
          error={!callbackVersion}
          helperText={!callbackVersion ? 'Callback Version required' : ''}
          inputProps={{ 'aria-label': 'Callback Version', 'data-testid': 'callback-version' }}
          select
          style={{ width: '20%' }}
          className="version-select"
        >
          {versions.map(version => (
            <MenuItem key={version} value={version}>
              {version}
            </MenuItem>
          ))}
        </TextField>
        <div className="disclaimer">
          The Callback URL and Callback Key are cached for 30 minutes. Please allow enough time for the cache to clear
          before testing changes.
        </div>
        {appCallbackUpdateError && (
          <div className="error">
            There was an error saving your application's settings: {findRtkErrorMessage(appCallbackUpdateError)}
          </div>
        )}
        <div className="application-setting-controls">
          <Button
            className="button-orange application-setting-save-button"
            onClick={updateCallback}
            disabled={isUpdatingAppCallback || !callbackUrl || !callbackKey || !callbackVersion}
            endIcon={isUpdatingAppCallback ? <CircularProgress size={20} /> : null}
          >
            Save
          </Button>
        </div>

        {isOwner && (
          <>
            <div className="application-setting-separator" />

            <div className="application-setting-user-management-wrapper">
              <span className="heading">User Management</span>

              <Button
                className="button-orange"
                onClick={() => setShowAddUserInput(!showAddUserInput)}
                endIcon={React.cloneElement(addUserIcon)}
              >
                Add User
              </Button>
              {showAddUserInput && (
                <div className="application-setting-add-user-wrapper">
                  {userInviteStatus === 'success' && (
                    <div className="user-invite-success">
                      <Check htmlColor="#33cccc" />

                      <span>User added successfully. Email invite sent.</span>
                    </div>
                  )}

                  {userInviteStatus === 'failed' && (
                    <div className="user-invite-error">
                      <Warning htmlColor="red" />

                      <span>Failed to invite user. Please try again later.</span>
                    </div>
                  )}
                  <span className="heading">Add User</span>

                  <span className="add-user-description">
                    Enter a work email and we'll send an invite to this address. The invited user will have the same
                    access as you, EXCEPT they will not be able to add or remove users.
                  </span>

                  <TextField
                    variant="outlined"
                    disabled={isLoading}
                    value={inviteUserEmail}
                    onChange={e => setInviteUserEmail(e.target.value)}
                    type="email"
                    placeholder="Work email address"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setInviteUserEmail('')}>
                            {inviteUserEmail ? <CloseIcon /> : ''}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button className="button-orange" onClick={onCreateUser}>
                    Invite
                  </Button>
                </div>
              )}
              <UsersTable appId={applicationId} applications={applications} />
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default ApplicationSetting;
