import { endOfDay, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { GetApplicationsPerformanceSummaryArgs } from './types';
import {
  PerformanceSummaryReport,
  PerformanceSummaryReportSumByApp,
  flattenAppStats,
  getPerformanceSummarySumByApp,
} from './utils';
import api from '../api/slice';
import { AppStats } from '../application/types';

const statsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAppsStats: builder.query<
      {
        appStats: AppStats[];
        flattenedAppStats: PerformanceSummaryReport[];
        statsByApp: PerformanceSummaryReportSumByApp[];
      },
      GetApplicationsPerformanceSummaryArgs
    >({
      query: args => {
        const params = {
          ...args.filters,
          start_date: zonedTimeToUtc(startOfDay(new Date(args.filters.start_date)), 'UTC').toISOString(),
          end_date: zonedTimeToUtc(endOfDay(new Date(args.filters.end_date)), 'UTC').toISOString(),
        };

        return {
          url: `stats/${args.adminAppId}/apps`,
          params: params,
          headers: {
            'X-WF-DeviceToken': args?.deviceToken || '',
          },
        };
      },
      transformResponse: (response: AppStats[], meta, args) => {
        return {
          appStats: response,
          flattenedAppStats: flattenAppStats(response, args.filters.sort_order, args.filters.sort_by! || '') || [],
          statsByApp:
            getPerformanceSummarySumByApp(response, args.filters.sort_order, args.filters.sort_by! || '') || [],
        };
      },
    }),
  }),
});

export const { useGetAppsStatsQuery } = statsApi;
export default statsApi;
