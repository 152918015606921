import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import _ from 'lodash';
import { toast } from 'react-toastify';
import AppButtonsList from 'components/AppGroupOverview/AppButtonsList/AppButtonsList';
import AppGroupHeader from 'components/AppGroupOverview/AppGroupHeader/AppGroupHeader';
import PerformanceSummaryTable from 'components/AppGroupOverview/PerformanceSummaryTable/PerformanceSummaryTable';
import Loading from 'components/Loading/Loading';
import NotFound from 'components/NotFound/Notfound';
import StatCards from 'components/StatCards/StatCards';
import { Columns } from 'helpers';
import { exportJSONToCSV, downloadCSV } from 'helpers';
import { DATE_FORMAT } from 'helpers/constants';
import { useAppSelector } from 'reduxState/hooks';
import { useGetAppsStatsQuery } from 'reduxState/store/performanceSummary/api';
import { initialNonAPIColumns } from 'reduxState/store/performanceSummary/constants';
import { performanceSummaryFilterValuesSelector } from 'reduxState/store/performanceSummary/selectors';
import {
  getPrevInterval,
  isPerformanceSummaryReportKey,
  isPerformanceSummaryReportSumByAppKey,
  PerformanceSummaryReport,
  PerformanceSummaryReportSumByApp,
} from 'reduxState/store/performanceSummary/utils';
import { useGetDeviceQuery, useGetUserApplicationsQuery } from 'reduxState/store/user/api';
import { RestrictedUserRoles } from 'reduxState/store/user/constants';
import { selectUserRestrictedRole } from 'reduxState/store/user/selectors';
import { AppGroup } from 'reduxState/store/user/types';
import './AppGroupOverview.scss';

const AppGroupOverview: FC = () => {
  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const [viewByApplication, setViewByApplication] = useState(false);
  const params = useParams();
  const adminAppId = params.adminAppId || '';

  const userRestrictedRole = useAppSelector(selectUserRestrictedRole);
  const { startDate, endDate, sortBy, sortOrder, groupBy } = useAppSelector(performanceSummaryFilterValuesSelector);
  const { start: prevStart, end: prevEnd } = getPrevInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });

  const { data: deviceData, error: deviceTokenError, isFetching: isDeviceTokenFetching } = useGetDeviceQuery(
    adminAppId,
  );
  const deviceToken = deviceData?.DeviceToken as string;

  if (!isDeviceTokenFetching && deviceTokenError) {
    console.error(deviceTokenError);
  }

  const { data: applicationsData, isFetching: isApplicationDataFetching } = useGetUserApplicationsQuery();
  const allAppGroups: AppGroup[] = applicationsData?.appGroups || [];
  const appGroup =
    allAppGroups.find((appGroup: AppGroup) => appGroup.adminId.toString() === adminAppId) || ({} as AppGroup);

  const { data, isFetching, error } = useGetAppsStatsQuery(
    {
      filters: {
        start_date: startDate,
        end_date: endDate,
        sort_by: sortBy,
        sort_order: sortOrder,
        application_ids: selectedApps,
        group_by: viewByApplication ? 'day' : groupBy === 'merchants' ? 'day' : groupBy, // default to day since merchants is currently not supported yet
      },
      deviceToken,
      adminAppId,
    },
    { skip: !adminAppId || !deviceToken || typeof deviceToken !== 'string' || selectedApps.length === 0 },
  );

  const { data: prevIntervalData } = useGetAppsStatsQuery(
    {
      filters: {
        start_date: prevStart.toString(),
        end_date: prevEnd.toString(),
        sort_by: sortBy,
        sort_order: sortOrder,
        application_ids: selectedApps,
        group_by: viewByApplication ? 'day' : groupBy === 'merchants' ? 'day' : groupBy, // default to day since merchants is currently not supported yet
      },
      deviceToken,
      adminAppId,
    },
    { skip: !adminAppId || !deviceToken || typeof deviceToken !== 'string' || selectedApps.length === 0 },
  );

  const downloadCSVFile = () => {
    if ((viewByApplication && !data?.statsByApp) || !data?.flattenedAppStats) {
      return;
    }

    let csv;
    if (viewByApplication) {
      const columnKeys: (keyof PerformanceSummaryReportSumByApp)[] = [];
      initialNonAPIColumns.forEach(({ accessor }) => {
        // replace ReportDate with ApplicationName
        if (accessor === 'ReportDate') {
          columnKeys.push('ApplicationName');
        } else if (typeof accessor === 'string' && isPerformanceSummaryReportSumByAppKey(accessor)) {
          columnKeys.push(accessor);
        }
      });

      csv = exportJSONToCSV(
        data?.statsByApp,
        columnKeys.map((key: keyof PerformanceSummaryReportSumByApp) => ({
          accessor: key,
          name: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        })),
      );
    } else {
      const statReportColumns: Columns<PerformanceSummaryReport>[] = [];
      initialNonAPIColumns.forEach(({ accessor, name }) => {
        if (typeof accessor === 'string' && isPerformanceSummaryReportKey(accessor)) {
          statReportColumns.push({ accessor, name });
        }
      });
      csv = exportJSONToCSV(data?.flattenedAppStats, statReportColumns);
    }

    if (!csv) return toast.error('There was an issue downloading your CSV data. Please try again.');

    const documentName = `${appGroup?.adminName.split(' ').join('-')}-${format(
      new Date(startDate),
      DATE_FORMAT,
    )}-${format(new Date(endDate), DATE_FORMAT)}`;

    downloadCSV(csv, documentName);
  };

  if (isApplicationDataFetching && _.isEmpty(appGroup)) {
    return <Loading />;
  }

  if (!isApplicationDataFetching && _.isEmpty(appGroup)) {
    return <NotFound />;
  }

  const currency = data?.appStats[0]?.ApplicationCurrency || 'USD';
  const currentIntervalDataStats = viewByApplication ? data?.statsByApp || [] : data?.flattenedAppStats || [];
  const prevIntervalDataStats = viewByApplication
    ? prevIntervalData?.statsByApp || []
    : prevIntervalData?.flattenedAppStats || [];

  // for entity 74 restricted users
  const isRestrictedOpsUser = userRestrictedRole === RestrictedUserRoles.OPERATIONS;

  return (
    <div className="app-group-overview">
      <div className="app-group-title-container">
        <h2 className="app-group-title-text">Welcome back, {appGroup.adminName}!</h2>
      </div>
      <AppButtonsList appGroup={appGroup} isRestrictedUser={isRestrictedOpsUser} />
      {!isRestrictedOpsUser && [
        <AppGroupHeader
          appGroup={appGroup}
          selectedApps={selectedApps}
          viewByApplication={viewByApplication}
          setViewByApplication={setViewByApplication}
          setSelectedApps={setSelectedApps}
          downloadCSVFile={downloadCSVFile}
        />,
        <StatCards
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          currency={currency}
          stats={!selectedApps.length ? [] : currentIntervalDataStats}
          prevIntervalStats={!selectedApps.length ? [] : prevIntervalDataStats}
          horizontalScroll
        />,
        <PerformanceSummaryTable
          selectedApps={selectedApps}
          adminAppId={adminAppId}
          currency={currency}
          flattenedAppGroupPerformanceSummary={!selectedApps.length ? [] : currentIntervalDataStats}
          viewByApplication={viewByApplication}
          isLoading={isFetching}
          error={error}
        />,
      ]}
    </div>
  );
};

export default AppGroupOverview;
