import React, { FC, useState } from 'react';
import { Chip } from '@mui/material';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { downloadCSV, findErrorMessage } from 'helpers';
import { useLazyGetCommissionDownloadQuery } from 'reduxState/store/commission/api';
import { CommissionHistoryDownloadData } from 'reduxState/store/commission/types';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import './CommissionHistoryReportDownload.scss';

interface CommissionHistoryReportDownloadProps {
  downloadData: CommissionHistoryDownloadData;
}

const CommissionHistoryReportDownload: FC<CommissionHistoryReportDownloadProps> = ({ downloadData }) => {
  const { CreatedDate, QueryString, Status } = downloadData;
  const [csvError, setCSVError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { data: deviceData, error: deviceTokenError } = useGetDeviceQuery(downloadData.ApplicationID.toString());
  const deviceToken = deviceData?.DeviceToken;
  const [getCommissionDownloadJob] = useLazyGetCommissionDownloadQuery();

  const generateReportName = (queryString: string) => {
    const match = queryString.match(/.*>"([^"]*)".*<"([^"]*)"\s*(.*)/);
    const startDate = match?.[1] || '';
    const endDate = match?.[2] || '';
    const searchQuery = match?.[3] || '';

    return `Commission History Report ${searchQuery ? `for ${searchQuery} ` : ''}(${startDate} - ${endDate})`;
  };

  const downloadCSVClick = async (reportName: string) => {
    if (deviceTokenError || !deviceToken) {
      console.error(deviceTokenError || 'Failed to retrieve device token.');
      return;
    }
    // Don't download the report again if it's in the middle of loading
    if (isLoading === true) {
      return;
    }

    setLoading(true);
    try {
      const { data, error } = await getCommissionDownloadJob({
        appId: downloadData.ApplicationID.toString(),
        deviceToken,
        downloadId: downloadData.ID.toString(),
      });

      if (error) {
        throw new Error(findErrorMessage(error));
      }

      downloadCSV(data, `${reportName}.csv`, window.document);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error(`Failed to download CSV for ID: ${downloadData.ID}`);
      setCSVError(true);
    }
  };

  const displayDate = format(new Date(CreatedDate), 'MMM dd, yyyy');
  const reportName = generateReportName(QueryString);
  const isReady = Status.toLowerCase() === 'finalized';

  return (
    <div className="commission-report-download">
      <div className="date">{displayDate}</div>
      {!csvError && (
        <div className={isReady ? 'link' : ''} onClick={() => isReady && downloadCSVClick(reportName)}>
          {isLoading ? 'Downloading CSV. One moment please' : reportName}
          <Chip label={Status} className={`status-chip ${Status.toLowerCase()}`} />
        </div>
      )}
      {csvError && <div>Error downloading report</div>}
    </div>
  );
};

export default CommissionHistoryReportDownload;
