import React, { FC, Key, ReactElement, ReactNode, CSSProperties, useCallback, useMemo, useState } from 'react';
import { Check, Refresh } from '@mui/icons-material';
import { Icon } from '@mui/material';
import { toast } from 'react-toastify';
import TableCell from 'components/TableCell/TableCell';
import TruncatedTableCellContent from 'components/TableCell/TruncatedTableCellContent';
import TableRow from 'components/TableRow/TableRow';
import { findErrorMessage, parseCurrencyAmount } from 'helpers';
import { formatUTCDate } from 'helpers/DateHelpers';
import { useLazyGetSendCommissionCallbackQuery } from 'reduxState/store/commission/api';
import {
  CommissionColumn,
  FilterStatus,
  CommissionWithMerchantName,
  CommissionColumnVisible,
} from '../../../reduxState/store/commission/types';
import './CommissionV2.scss';

interface SendCallback {
  data: unknown | null;
  success: boolean;
  loading: boolean;
  error: boolean;
}

export interface CommissionV2Props {
  data: {
    items: CommissionWithMerchantName[];
    applicationId: string;
    columns: CommissionColumnVisible[];
    statusFilters: FilterStatus;
    callbackVisible: boolean;
  };
  index: number;
  style: CSSProperties;
}

const formatDate = (date: string) => {
  const formattedDate = formatUTCDate(new Date(date), 'yyyy-MM-dd HH:mm');
  return `${formattedDate} UTC`;
};

const CommissionV2: FC<React.PropsWithChildren<CommissionV2Props>> = ({
  index,
  style,
  data: { columns, items, applicationId, statusFilters, callbackVisible },
}) => {
  const commission = useMemo(() => items[index], [items, index]);
  const [sendCallbackQuery] = useLazyGetSendCommissionCallbackQuery();
  const [sendCallback, setSendCallback] = useState<SendCallback>({
    data: null,
    success: false,
    loading: false,
    error: false,
  });

  const handleSendCommissionCallback = useCallback(async (): Promise<void> => {
    setSendCallback(current => ({ ...current, data: null, loading: true }));

    try {
      const { data, error } = await sendCallbackQuery({ appId: applicationId, commissionId: commission.CommissionID });

      if (error) {
        throw new Error(findErrorMessage(error));
      }

      setSendCallback(current => ({ ...current, data, success: true, loading: false }));
      setTimeout(() => {
        setSendCallback(current => ({ ...current, success: false }));
      }, 3000);
      toast.success(`Successful resend callback for Commission: ${commission.CommissionID}`);
    } catch (error) {
      setSendCallback({
        data: null,
        loading: false,
        error: true,
        success: false,
      });
      toast.error(`Failed to resend callback for Commission: ${commission.CommissionID}`);
    }
  }, [applicationId, commission.CommissionID]);

  const renderStatus = useCallback((status: string): ReactElement => {
    return <div className={`cell-status-text status-${status.toLowerCase()}`}>{status}</div>;
  }, []);

  const renderContent = useCallback(
    ({ accessor }: CommissionColumn): ReactElement => {
      switch (accessor) {
        case 'EventDate':
        case 'ModifiedDate':
        case 'CreatedDate':
          return <>{formatDate(commission[accessor])}</>;

        case 'PartnerCommission':
        case 'UserCommission':
        case 'SaleAmount':
          return <>{parseCurrencyAmount(commission[accessor].amount, commission[accessor].currency)}</>;

        case 'Status':
          return <>{renderStatus(commission[accessor])}</>;

        case 'MerchantName':
        case 'DeviceID':
        case 'MerchantSKU':
        case 'MerchantOrderID':
        case 'TrackingCode':
          return <TruncatedTableCellContent content={commission[accessor]} canCopy={true} />;

        default: {
          return <>{commission[accessor]}</>;
        }
      }
    },
    [commission, renderStatus],
  );

  const renderCell = useCallback(
    (content: ReactNode, key: Key): ReactElement => {
      return (
        <TableCell role="cell" title={(commission[key as CommissionColumn['accessor']] as string) || ''} key={key}>
          {content}
        </TableCell>
      );
    },
    [commission],
  );

  const renderedColumns = useMemo((): ReactElement[] => {
    return columns.map((column: CommissionColumn) => {
      const { accessor } = column;
      return renderCell(renderContent(column), accessor);
    });
  }, [columns, renderContent, renderCell]);

  const callbackColumn = useMemo((): ReactElement => {
    const content = (
      <span onClick={handleSendCommissionCallback}>
        <Icon color={sendCallback.loading ? 'action' : 'inherit'}>
          {sendCallback.success ? <Check data-testid="check-icon" /> : <Refresh data-testid="refresh-icon" />}
        </Icon>
      </span>
    );

    return renderCell(content, 'callback');
  }, [handleSendCommissionCallback, sendCallback, renderCell]);
  return (
    <table>
      <tbody>
        <TableRow className="commission" style={style} role="row">
          {renderedColumns}
          {callbackVisible && callbackColumn}
        </TableRow>
      </tbody>
    </table>
  );
};

export default CommissionV2;
