import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Edit, Undo } from '@mui/icons-material';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { useUpdateApplicationManagementMutation } from 'reduxState/store/applicationManagement/api';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import { ConfirmEdit } from './ConfirmEdit';
import { ApplicationManagement } from '../../../reduxState/store/applicationManagement/types';
import '../scss/ProductPageUrl.scss';

export interface Props {
  applicationId: string;
  applicationManagement: ApplicationManagement;
}

export const ProductPageUrl = ({ applicationId, applicationManagement }: Props) => {
  const { user } = useAuth0();

  const [isDisabled, setDisabled] = useState(true);
  const [value, setValue] = useState(applicationManagement.ProductPageURL);
  const [updateApplicationManagement] = useUpdateApplicationManagementMutation();

  const { data: deviceData, error: deviceTokenError } = useGetDeviceQuery(applicationId);
  const deviceToken = deviceData?.DeviceToken;

  const cancelEdit = () => {
    setValue(applicationManagement.ProductPageURL);
    setDisabled(true);
  };

  const handleSave = () => {
    if (deviceToken) {
      updateApplicationManagement({
        appId: applicationId,
        applicationManagement: { ...applicationManagement, ProductPageURL: value },
        author: user?.email ?? 'missing email',
        deviceToken,
      });
      setDisabled(true);
    } else if (deviceTokenError) {
      toast.error('Failed to save due to missing device token. Please try again.');
      console.error(deviceTokenError);
    }
  };

  return (
    <div className="product-page-url settings-form-group ">
      <TextField
        id="productPageUrl"
        className={`product-page-url-input ${!isDisabled ? 'user-editing' : ''}`}
        label="Product Page URL"
        value={value}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        inputRef={input => input && !isDisabled && input.focus()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {!isDisabled ? (
                <Tooltip title="Cancel editing" role="tooltip">
                  <Undo onClick={cancelEdit} className="edit-icon-undo" data-testid="undo-button" />
                </Tooltip>
              ) : (
                <Edit onClick={() => setDisabled(d => !d)} className="edit-icon" data-testid="edit-button" />
              )}
            </InputAdornment>
          ),
        }}
        onChange={e => setValue(e.target.value)}
        disabled={isDisabled}
      />
      {!isDisabled && (
        <ConfirmEdit
          headerText={'Edit Product Page URL'}
          confirmText={`Confirm update to product page url:`}
          changeText={value}
          onSave={handleSave}
        />
      )}
    </div>
  );
};
