import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { findAppNameFromId } from 'helpers/index';
import { useAppSelector } from 'reduxState/hooks';
import { RestrictedUserRoles } from 'reduxState/store/user/constants';
import { selectUserRestrictedRole } from 'reduxState/store/user/selectors';
import { Application } from 'reduxState/store/user/types';
import './ApplicationDropdownMenu.scss';

interface ApplicationDropdownMenuProps {
  appId?: string;
  applications: Application[];
  adminAppId: string;
}

const ApplicationDropdownMenu: FC<ApplicationDropdownMenuProps> = ({ appId, applications, adminAppId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeApplicationName, setActiveApplicationName] = useState<null | string>(null);
  const restrictedUser = useAppSelector(selectUserRestrictedRole);
  const navigate = useNavigate();

  useEffect(() => {
    setActiveApplicationName(findAppNameFromId(applications, appId || ''));
  }, [applications, appId]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (application: Application) => {
    setAnchorEl(null);
    const defaultPage = restrictedUser === RestrictedUserRoles.OPERATIONS ? 'commission-history' : 'summary';
    navigate(`/${adminAppId}/app/${application.id}/${defaultPage}`);
  };

  return (
    <>
      {activeApplicationName ? (
        <div className="application-list-container">
          <Button
            classes={{ root: 'active-application-button truncate' }}
            aria-controls="application-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {activeApplicationName}
          </Button>
          <Menu
            id="application-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PopoverClasses={{ paper: 'application-menu-popup' }}
            classes={{ list: 'application-list-popup' }}
            disableScrollLock
          >
            {applications.map((application: Application) => (
              <MenuItem
                key={application.name}
                classes={{ root: `list-item ${application.name === activeApplicationName ? 'active' : 'inactive'}` }}
                onClick={() => handleSelect(application)}
              >
                {application.name}
              </MenuItem>
            ))}
            <MenuItem
              classes={{ root: 'list-item view-all' }}
              onClick={() => {
                handleClose();
                navigate(`/${adminAppId}`);
              }}
            >
              App Group Overview
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ApplicationDropdownMenu;
