import React from 'react';
import { LinearProgress } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import {
  FeaturedMerchantRecord,
  FeaturedMerchantSortType,
  MerchantType,
} from '../../../reduxState/store/featuredMerchant/types';
import SortButtons from '../FeaturedMerchantButtons/SortButtons';
import Merchant from '../Merchant/Merchant';
import './MerchantList.scss';

export interface MerchantListProps {
  title: string;
  merchants: MerchantType[];
  onSelect: (merchant: MerchantType) => void;
  selected: FeaturedMerchantRecord;
  merchantsToSort?: FeaturedMerchantRecord | null;
  onSort?: (merchant: MerchantType, sortType: FeaturedMerchantSortType) => void;
  isLoading: boolean;
}

const MerchantList = ({
  title,
  merchants,
  onSelect,
  selected,
  onSort,
  merchantsToSort = null,
  isLoading,
}: MerchantListProps): JSX.Element => {
  return (
    <div className="merchant-list">
      <div className="merchant-table-header">
        <h4 className="merchant-title">{title}</h4>
        {onSort && <SortButtons onSort={onSort} selected={selected} isLoading={isLoading} data-testid="sort-button" />}
      </div>
      {isLoading && <LinearProgress />}
      <div style={{ flex: '1 1 auto' }}>
        <AutoSizer>
          {/* @ts-ignore */}
          {({ height, width }): JSX.Element => (
            <FixedSizeList
              height={height || 10}
              width={width || 10}
              itemSize={32}
              itemCount={merchants.length}
              itemData={merchants}
            >
              {(props): JSX.Element => (
                <Merchant {...props} onSelect={onSelect} selected={selected} merchantsToSort={merchantsToSort} />
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default MerchantList;
