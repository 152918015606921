import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MenuItem, Select, Button, CircularProgress, Paper } from '@mui/material';
import { capitalize } from 'lodash';
import { toast } from 'react-toastify';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import { findErrorMessage } from 'helpers';
import {
  useAddConceptsMutation,
  useGetConceptsQuery,
  useGetIsAllowListQuery,
  useRemoveConceptsMutation,
  useSyncConceptsMutation,
} from 'reduxState/store/concept/api';
import { KINDS } from 'reduxState/store/concept/constants';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import { selectUserEmail } from 'reduxState/store/user/selectors';
import ConceptList from './ConceptList/ConceptList';
import { useAppSelector } from '../../reduxState/hooks';
import { isConceptKind } from '../../reduxState/store/concept/typeGuards';
import { Concept, ConceptKinds } from '../../reduxState/store/concept/types';
import './ConceptManager.scss';

export type selectedConceptsType = Record<Concept['ID'], Concept>;

// Used to track expected changes in concepts after saving.
// When these conditions are met, it is safe to sync across all applications.
interface ConceptUpdateReport {
  current: {
    newLength: number;
    newConcepts: string[];
  };
  addable: {
    newLength: number;
    newConcepts: string[];
  };
}

interface ConceptManagerProps {
  applicationId: string;
}

const ConceptManager = ({ applicationId }: ConceptManagerProps): JSX.Element => {
  const userEmail = useAppSelector(selectUserEmail);
  const [kind, setKind] = useState<ConceptKinds>('domain');
  const [error, setError] = useState('');
  const [conceptUpdateReport, setConceptUpdateReport] = useState<ConceptUpdateReport | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedAddableRef = useRef<selectedConceptsType>({});
  const selectedCurrentRef = useRef<selectedConceptsType>({});
  const conceptEditSavedCallback = useRef<() => void>(() => null);
  const { data: isAllowList, error: isAllowListError, isError: hasIsAllowListError } = useGetIsAllowListQuery(
    applicationId,
  );

  const [addConcepts, { isLoading: isAddingConceptsLoading }] = useAddConceptsMutation();
  const [removeConcepts, { isLoading: isRemovingConceptsLoading }] = useRemoveConceptsMutation();
  const [syncConcepts] = useSyncConceptsMutation();

  const { data: deviceData, error: deviceTokenError } = useGetDeviceQuery(applicationId);
  const deviceToken = deviceData?.DeviceToken;
  if (hasIsAllowListError) {
    toast.error(`Failed to get application's IsAllowList.`);
    console.error(isAllowListError);
  }

  const getConceptsBaseArgs = {
    appId: applicationId,
    deviceToken: deviceToken!,
    kind,
  };

  const {
    data: currentConcepts,
    isFetching: isCurrentConceptsFetching,
    refetch: refetchCurrentConcepts,
  } = useGetConceptsQuery(getConceptsBaseArgs, {
    pollingInterval: Boolean(conceptUpdateReport) ? 5000 : 0, // polling interval of 0 stops polling. we should only be polling while an update is in progress.
  });
  const {
    data: addableConcepts,
    isFetching: isAddableConceptsFetching,
    refetch: refetchAddableConcepts,
  } = useGetConceptsQuery(
    {
      ...getConceptsBaseArgs,
      addable: true,
    },
    {
      pollingInterval: Boolean(conceptUpdateReport) ? 5000 : 0, // polling interval of 0 stops polling. we should only be polling while an update is in progress.
    },
  );

  const isConceptsSaving = isAddingConceptsLoading || isRemovingConceptsLoading;
  const isConceptsFetching = isCurrentConceptsFetching || isAddableConceptsFetching;

  const editConcepts = async (shouldSync = false) => {
    // users should not be able to save concepts without isAllowList or deviceToken
    if (deviceTokenError || !deviceToken || hasIsAllowListError) {
      toast.error('Unable to save concepts.');
      if (deviceTokenError) {
        console.error(deviceTokenError || 'Failed to retrieve device token.');
      }
      return;
    }

    if (!addableConcepts || !currentConcepts) return;
    const newAddedConcepts = Object.keys(selectedAddableRef.current);
    const newRemoveConcepts = Object.keys(selectedCurrentRef.current);

    let conceptsToAdd: string[] = [];
    let conceptsToRemove: string[] = [];

    // if isAllowList is true, business as usual
    // otherwise, do the opposite
    if (isAllowList) {
      conceptsToAdd = newAddedConcepts;
      conceptsToRemove = newRemoveConcepts;
    } else {
      conceptsToAdd = newRemoveConcepts;
      conceptsToRemove = newAddedConcepts;
    }

    if (shouldSync) {
      setConceptUpdateReport({
        current: {
          newLength: currentConcepts.concepts.length + newAddedConcepts.length - newRemoveConcepts.length,
          newConcepts: newAddedConcepts,
        },
        addable: {
          newLength: addableConcepts.concepts.length + newRemoveConcepts.length - newAddedConcepts.length,
          newConcepts: newRemoveConcepts,
        },
      });
    }

    try {
      const addConceptsPromise = addConcepts({
        appId: applicationId,
        deviceToken,
        body: { shortCodes: conceptsToAdd, author: userEmail },
      }).unwrap();
      const removeConceptsPromise = removeConcepts({
        appId: applicationId,
        deviceToken,
        body: { shortCodes: conceptsToRemove, author: userEmail },
      }).unwrap();
      await Promise.all([addConceptsPromise, removeConceptsPromise]);
      !shouldSync && toast.success('Concepts saved successfully!');
      conceptEditSavedCallback.current();
    } catch (errorResponse) {
      let errorMessage = 'Failed to add/remove concepts.';
      const error = errorResponse.data;
      if (error?.ErrorMessage) {
        errorMessage = capitalize(error.errorMessage);
      }
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  const refetchConcepts = () => {
    refetchAddableConcepts();
    refetchCurrentConcepts();
    setError('');
  };

  useEffect(() => {
    let conceptKind: ConceptKinds = kind;
    const queryKind = searchParams.get('kind');

    if (isConceptKind(queryKind)) {
      conceptKind = queryKind;
      setKind(conceptKind);
    }
  }, [searchParams, kind, applicationId]);

  useEffect(() => {
    if (!currentConcepts || !addableConcepts || !deviceToken || !conceptUpdateReport) return;

    const { current, addable } = conceptUpdateReport;
    if (
      current.newLength === currentConcepts.concepts.length &&
      addable.newLength === addableConcepts.concepts.length
    ) {
      const newCurrentConceptsAdded = new Set(current.newConcepts);
      const newAddableConceptsAdded = new Set(addable.newConcepts);

      const foundNewCurrentConceptsCount = currentConcepts.concepts.reduce(
        // if the current concept for this iteration is one of the concepts that are being added, increase count by one.
        (foundCount, concept) => foundCount + Number(newCurrentConceptsAdded.has(concept.ID)),
        0,
      );
      const foundNewAddableConceptsCount = addableConcepts.concepts.reduce(
        // if the addable concept for this iteration is one of the concepts that are being made addable, increase count by one.
        (foundCount, concept) => foundCount + Number(newAddableConceptsAdded.has(concept.ID)),
        0,
      );

      if (
        foundNewAddableConceptsCount === newAddableConceptsAdded.size &&
        foundNewCurrentConceptsCount === newCurrentConceptsAdded.size
      ) {
        syncConcepts({
          appId: applicationId,
          deviceToken,
          body: { applicationId: Number(applicationId), author: userEmail },
        })
          .unwrap()
          .then(() => {
            toast.success(
              'Concepts have been updated successfully! Concepts are now being synced across all applications.',
            );
          })
          .catch(error => {
            toast.error(`Unable to sync concepts across all applications: ${findErrorMessage(error)}`);
            console.error(error);
          })
          .finally(() => setConceptUpdateReport(null));
      }
    }
  }, [currentConcepts, addableConcepts]);

  return (
    <div className="concept-manager">
      <div className="concept-manager-main">
        <h2 className="text-4xl">Concepts</h2>
        <Select
          variant="standard"
          className="kind"
          value={kind}
          onChange={e => {
            searchParams.set('kind', e.target.value);
            setSearchParams(searchParams);
          }}
        >
          {KINDS.map((kind: ConceptKinds) => (
            <MenuItem key={kind} value={kind}>
              {kind}
            </MenuItem>
          ))}
        </Select>
      </div>
      {error ? (
        <Error retry={() => refetchConcepts()} />
      ) : (
        <div className="concept-list-manager">
          <div className="button-wrapper">
            {Boolean(conceptUpdateReport) ? (
              <Paper elevation={3} className="sync-notification">
                <CircularProgress color="info" size="2rem" />
                <p style={{ paddingLeft: '10px', width: '425px' }}>
                  Please remain on this page until all updates are saved. Once concepts are updated successfully, you
                  may navigate away.
                </p>
              </Paper>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isConceptsSaving || isConceptsFetching || hasIsAllowListError}
                  onClick={() => editConcepts(false)}
                >
                  {isConceptsSaving ? (
                    <>
                      <CircularProgress color="info" size="0.875rem" />{' '}
                      <span style={{ paddingLeft: '10px' }}>Saving...</span>
                    </>
                  ) : (
                    <span>Save</span>
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isConceptsSaving || isConceptsFetching || hasIsAllowListError}
                  onClick={() => editConcepts(true)}
                >
                  Save and Sync
                </Button>
              </>
            )}
          </div>
          <div className="compare-concepts">
            <ConceptList
              title="Current"
              isLoading={Boolean(isCurrentConceptsFetching || conceptUpdateReport)}
              concepts={currentConcepts?.concepts || ([] as Concept[])}
              selectedRef={selectedCurrentRef}
              conceptEditSavedCallback={conceptEditSavedCallback}
            />
            <ConceptList
              title="Addable"
              isLoading={Boolean(isAddableConceptsFetching || conceptUpdateReport)}
              concepts={addableConcepts?.concepts || ([] as Concept[])}
              selectedRef={selectedAddableRef}
              conceptEditSavedCallback={conceptEditSavedCallback}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConceptManager;
