import { toast } from 'react-toastify';
import { SERVER_ENDPOINT, DATA_URLS } from './constants';
import { Policy } from '../reduxState/store/applicationManagement/types';
import { isActiveDomain, isMerchant, isMerchantCommissionRate } from '../reduxState/store/merchant/typeGuards';
import { MerchantCommissionRate, Merchant, ActiveDomain } from '../reduxState/store/merchant/types';
import { MerchantSearchResult } from '../reduxState/store/performanceSummary/types';
import { Device } from '../reduxState/store/user/types';

export interface ErrorResponse {
  status: number;
  message: string;
}

const handleTooManyRequests = async (res: Response) => {
  if (res.status === 429) {
    toast.error("You've made too many requests in a short time. Please wait a moment and try again.");
  }
};

export const request = async (
  method: string,
  path: string,
  deviceToken = '',
  body?: Record<string, unknown>,
): Promise<Response> => {
  const auth0AccessToken = localStorage.getItem('accessToken');

  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-WF-UserAgent': 'partner-admin',
      'X-WF-DeviceToken': deviceToken,
      Authorization: `Bearer ${auth0AccessToken}`,
    },
    credentials: 'include',
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(`${SERVER_ENDPOINT}${path}`, options);
  handleTooManyRequests(response);
  return response;
};

export const createDevice = async (appId: string): Promise<Device | ErrorResponse> => {
  const response = await request('POST', `/api/${appId}/device`);
  return await response.json();
};

export const getMerchants = async (
  appId: string,
  deviceToken: string,
  ids: number[],
): Promise<Merchant[] | ErrorResponse | undefined> => {
  const response = await request('GET', `/api/${appId}/merchant?id=${ids.join('&id=')}`, deviceToken);

  if (response.ok) {
    const data = await response.json();
    for (let i = 0; i < data.Merchants.length; i++) {
      const merchant = data.Merchants[i];
      const checkResult = isMerchant(merchant);

      if (!checkResult) {
        throw new Error(`Merchant with ID ${merchant.ID} has incorrect values.`);
      }
    }

    return data.Merchants;
  }
};

export const searchMerchants = async (
  searchValue: string,
  appId: string,
): Promise<MerchantSearchResult[] | ErrorResponse> => {
  try {
    const response = await request('GET', `/api/${appId}/search?q=${encodeURIComponent(searchValue)}`);
    return await response.json();
  } catch (error) {
    console.log(error.message || error);
    return [];
  }
};

export const getMerchantCommissionRates = async (
  appId: string,
  deviceToken: string,
  merchantId: number,
): Promise<MerchantCommissionRate[] | ErrorResponse | undefined> => {
  const response = await request('GET', `/api/${appId}/merchant/${merchantId}/commission`, deviceToken);

  if (response.ok) {
    const commissionRates = await response.json();

    for (let i = 0; i < commissionRates.length; i++) {
      const rate = commissionRates[i];
      const checkResult = isMerchantCommissionRate(rate);

      if (!checkResult) {
        throw new Error(`Merchant commission rate for merchant with ID ${merchantId} has incorrect values.`);
      }
    }

    return commissionRates;
  }
};

export const getActiveDomains = async (appId: string): Promise<ActiveDomain[] | ErrorResponse> => {
  // get active domains data from JSON
  const activeDomainsResponse = await fetch(`${DATA_URLS.CLOUD_DATA_BASE_URI}/${appId}/active-domain/1`);

  const response = await activeDomainsResponse.json();

  // check for correct data in response
  for (let i = 0; i < response.length; i++) {
    const domain = response[i];
    const checkResult = isActiveDomain(domain);

    if (!checkResult) {
      throw new Error(`Active Domain with ID ${domain.ID} has incorrect values.`);
    }
  }

  return response;
};

export const getAllSplitPolicies = async (): Promise<Policy[] | ErrorResponse> => {
  try {
    const response = await request('GET', '/api/policy/all');
    return response.json();
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

export const createTestTransactions = async (applicationId: number, trackingCode: string): Promise<any> => {
  const response = await request('POST', `/api/${applicationId}/create-test-transactions`, '', {
    applicationId,
    trackingCode,
  });
  const responseJSON = await response.json();
  if (!response.ok) {
    throw new Error(responseJSON.ErrorMessage);
  }
  return responseJSON;
};
