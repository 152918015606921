import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Header from 'components/Header/Header';
import Loading from 'components/Loading/Loading';
import RedirectToPath from 'components/Route/RedirectToPath';
import { hasWildfirePermissionsSelector, userRolesSelector } from 'helpers/auth0';
import AppGroupOverview from 'pages/AppGroupOverview/AppGroupOverview';
import ApplicationGroups from 'pages/AppGroups/AppGroups';
import Application from 'pages/Application/Application';
import BoostedOffers from 'pages/BoostedOffers/BoostedOffers';
import CreateApplication from 'pages/CreateApplication/CreateApplication';
import FeatureDashboard from 'pages/FeatureDashboard/FeatureDashboard';
import Login from 'pages/Login/Login';
import { selectUserToken } from 'reduxState/store/user/selectors';
import { setUser, setUserRestrictedRole } from 'reduxState/store/user/slice';
import { getHighestRestrictedUserRole } from 'reduxState/store/user/utils';
import { useAppDispatch, useAppSelector } from '../../reduxState/hooks';
import './Main.scss';

const Main = (): JSX.Element => {
  const location = useLocation();
  const auth0 = useAuth0();
  const { isAuthenticated, isLoading: loadingUser, loginWithRedirect, user, getAccessTokenSilently, error } = auth0;
  const hasWfPermissions = hasWildfirePermissionsSelector(user);
  const accessToken = useAppSelector(selectUserToken);

  const lastLoginDate = localStorage.getItem('lastLoginDate');
  if (lastLoginDate === '' || Number(lastLoginDate) < new Date(Date.UTC(2022, 12, 9)).getTime()) {
    localStorage.removeItem('commission_columns');
    localStorage.setItem('lastLoginDate', JSON.stringify(Date.now()));
  }
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAuthenticated && !loadingUser) {
      loginWithRedirect({
        appState: {
          returnTo: location?.pathname,
        },
      });
    }
  }, [isAuthenticated, loadingUser, location.pathname, loginWithRedirect]);

  useEffect(() => {
    if (user) {
      getAccessTokenSilently().then((token: string) => {
        localStorage.setItem('accessToken', token);
        dispatch(setUser({ token: token, user: user, email: user.email || 'Not Available' }));
      });
      const userRoles = userRolesSelector(user);
      const highestRestrictedRole = getHighestRestrictedUserRole(userRoles);

      if (highestRestrictedRole) {
        dispatch(setUserRestrictedRole({ restrictedRole: highestRestrictedRole }));
      }
    }
  }, [dispatch, getAccessTokenSilently, user]);

  if (loadingUser && !error) {
    return (
      <div className="main">
        <div className="loading-wrapper">
          <Loading />
        </div>
      </div>
    );
  }
  if (error) {
    console.error('Error authenticating user.');
    return (
      <div className="main">
        <h4>Error authenticating user. Please try logging in again.</h4>
      </div>
    );
  }

  if (!isAuthenticated || !accessToken) {
    return <></>;
  }

  return (
    <div className="main">
      <Routes>
        <Route element={<Header />}>
          <Route path="/login" element={<Login />} />
          <Route path="/features" element={<FeatureDashboard />} />
          {hasWfPermissions && <Route path="/create-application" element={<CreateApplication />} />}
          <Route path="/:adminAppId/boosted-offers" element={<BoostedOffers />} />
          <Route path="/" element={<ApplicationGroups />} />
          <Route path="*" element={<RedirectToPath path="/login" />} />
          <Route path="/:adminAppId" element={<AppGroupOverview />} />
          <Route path="/:adminAppId/app/:appId/*" element={<Application />} />
          {/** Old routes that are not in use anymore, redirect user to AppGroup page. Leaving in for now for users with old bookmarks */}
          <Route path="/apps/:appId/*" element={<RedirectToPath path="/" />} />
          <Route path="/apps" element={<RedirectToPath path="/" />} />
        </Route>
      </Routes>
    </div>
  );
};

export default Main;
