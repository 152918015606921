import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'reduxState/hooks';
import { selectUserRestrictedRole } from 'reduxState/store/user/selectors';
import { defaultNavigationTabs, NavTab, getRestrictedRoleTabs } from './constants';
import './NavTabs.scss';

interface NavTabsProps {
  appId: string;
  adminAppId: string;
}

const NavTabs: FC<NavTabsProps> = ({ appId, adminAppId }) => {
  const location = useLocation();
  const theme = useTheme();
  const isInSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const restrictedRole = useAppSelector(selectUserRestrictedRole);

  const restrictedUserRoleTabs = restrictedRole !== null ? getRestrictedRoleTabs(restrictedRole) : null;

  const renderTabs = () => {
    const renderTab = (tab: NavTab) => {
      return (
        <Link
          key={tab.name}
          to={`/${adminAppId}/app/${appId}/${tab.pathname}`}
          className={`tab ${location.pathname.includes(tab.pathname) ? 'active' : ''}`}
        >
          {tab.name}
        </Link>
      );
    };

    if (restrictedRole && restrictedUserRoleTabs) return restrictedUserRoleTabs?.map(renderTab);
    return defaultNavigationTabs.map(renderTab);
  };

  return !isInSmallScreen ? <div className="tab-list">{renderTabs()}</div> : <></>;
};

export default NavTabs;
