import { RestrictedUserRoles } from 'reduxState/store/user/constants';

export interface NavTab {
  name: string;
  pathname: string;
}

const performanceSummaryNavTab = {
  name: 'Performance',
  pathname: 'summary',
};

const commissionHistoryNavTab = {
  name: 'Commissions',
  pathname: 'commission-history',
};

const paymentsNavTab = {
  name: 'Payments',
  pathname: 'payment',
};

const merchantsNavTab = {
  name: 'Merchants',
  pathname: 'merchants',
};

const defaultNavigationTabs = [performanceSummaryNavTab, commissionHistoryNavTab, paymentsNavTab, merchantsNavTab];

// RestrictedRoles nav tabs
const operationsNavTabs = [commissionHistoryNavTab, merchantsNavTab];
const financeNavTabs = [performanceSummaryNavTab, commissionHistoryNavTab, paymentsNavTab];

const getRestrictedRoleTabs = (role: string): NavTab[] | undefined => {
  switch (role) {
    // Entity 74 Operations Team
    case RestrictedUserRoles.OPERATIONS:
      return operationsNavTabs;

    // Entity 74 Finance
    case RestrictedUserRoles.FINANCE:
      return financeNavTabs;

    // Entity 74 Business Team & Entity 74 Administrator
    case RestrictedUserRoles.BUSINESS:
    case RestrictedUserRoles.ADMIN: {
      return defaultNavigationTabs;
    }
  }
};

export { getRestrictedRoleTabs, defaultNavigationTabs };
