import React, { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Edit, Undo } from '@mui/icons-material';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { useUpdateApplicationManagementMutation } from 'reduxState/store/applicationManagement/api';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import { ConfirmEdit } from './ConfirmEdit';
import { ApplicationManagement } from '../../../reduxState/store/applicationManagement/types';
import '../scss/AppEntityName.scss';

interface AppEntityNameProps {
  applicationId: string;
  Name: string;
  applicationManagement: ApplicationManagement | null;
}

export const AppEntityName: FC<React.PropsWithChildren<AppEntityNameProps>> = ({
  applicationId,
  Name,
  applicationManagement,
}) => {
  const { user } = useAuth0();
  const [isDisabled, setDisabled] = useState(true);
  const [applicationEntityName, setApplicationEntityName] = useState(Name);
  const [updateApplicationManagement] = useUpdateApplicationManagementMutation();

  const { data: deviceData } = useGetDeviceQuery(applicationId);
  const deviceToken = deviceData?.DeviceToken;

  useEffect(() => {
    if (Name) setApplicationEntityName(Name);
  }, [Name]);

  const saveApplicationName = (): void => {
    if (!applicationManagement?.EntityName || !deviceToken) return;
    applicationManagement.EntityName = applicationEntityName;
    updateApplicationManagement({
      appId: applicationId,
      applicationManagement,
      author: user!.email!,
      deviceToken,
    });
  };

  const toggleDisabled = (): void => setDisabled(prevState => !prevState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setApplicationEntityName(event.currentTarget.value);
  };

  const cancelEdit = (): void => {
    setApplicationEntityName(Name);
    setDisabled(true);
  };

  const handleSave = (): void => {
    saveApplicationName();
    toggleDisabled();
  };

  return (
    <div className="application-entity-name settings-form-group ">
      <TextField
        id="applicationEntityName"
        className={`application-entity-name-input ${!isDisabled ? 'user-editing' : ''}`}
        label="Application Entity Name"
        value={applicationEntityName}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        inputRef={input => input && !isDisabled && input.focus()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {!isDisabled ? (
                <Tooltip title="Cancel editing">
                  <Undo onClick={cancelEdit} className="edit-icon" />
                </Tooltip>
              ) : (
                <Edit onClick={toggleDisabled} className="edit-icon" />
              )}
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
        disabled={isDisabled}
      />
      {!isDisabled && (
        <ConfirmEdit
          headerText={'Edit Application Entity Name'}
          confirmText={`Confirm save to update application entity name:`}
          changeText={applicationEntityName}
          onSave={handleSave}
        />
      )}
    </div>
  );
};
