import React, { FC } from 'react';
import TableHead from '@mui/material/TableHead';
import DraggableTableHeader from 'components/DraggableTableHeader/DraggableTableHeader';
import { useAppDispatch } from 'reduxState/hooks';
import { initialCommissionColumns } from 'reduxState/store/commission/constants';
import { setCommissionColumns } from 'reduxState/store/commission/slice';
import { CommissionColumn, CommissionColumnVisible } from '../../../reduxState/store/commission/types';

export interface CommissionHistoryTableHeaderProps {
  columns: CommissionColumnVisible[];
  className?: string;
  callbackVisible: boolean;
  onReorganize: (columns?: CommissionColumnVisible[]) => void;
}

export const CommissionHistoryTableHeader: FC<CommissionHistoryTableHeaderProps> = ({
  columns,
  className,
  callbackVisible,
}) => {
  const dispatch = useAppDispatch();

  // used for Reorganizable Header
  const onReorganize = (updatedColumns: CommissionColumnVisible[]): void => {
    const newColumns: CommissionColumn[] = [...updatedColumns];
    const newColumnsSet = new Set(updatedColumns.map(x => x.accessor));
    initialCommissionColumns.forEach((commissionColumn: CommissionColumn) => {
      if (!newColumnsSet.has(commissionColumn.accessor)) {
        newColumns.push({
          ...commissionColumn,
          visible: false,
        });
      }
    });
    dispatch(
      setCommissionColumns({
        columns: newColumns,
      }),
    );
  };

  return (
    <TableHead component="div" sx={{ display: 'table-header-group' }} className={`table-header-container ${className}`}>
      <div className={`table-header-row`} style={{ display: 'flex', flex: '1' }}>
        <DraggableTableHeader initialColumns={columns} onReorganize={onReorganize} callbackVisible={callbackVisible} />
      </div>
    </TableHead>
  );
};
