import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ExpandMore, UnfoldMore, DisabledByDefaultRounded } from '@mui/icons-material';
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, Checkbox } from '@mui/material';
import Loading from 'components/Loading/Loading';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { formatUTCDate } from 'helpers/DateHelpers';
import { BoostedOffer, BoostedOfferSortBy, CampaignsData, CampaignsType } from 'reduxState/store/boostedOffers/types';
import { tableHeaders } from './constants';
import TableSortLabel from '../../TableSortLabel/TableSortLabel';
import './BoostedOffersTable.scss';

interface BoostedOffersTableProps {
  campaignsData: CampaignsData;
  campaignsType: CampaignsType;
  error: string | Error;
  isLoading: boolean;
  isDeleteMode: boolean;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  sortTable: (header: BoostedOfferSortBy) => void;
  toggleCampaignToDelete: (campaign: BoostedOffer, shouldBeDeleted: boolean) => void;
  setCampaignToEdit: (campaign: BoostedOffer | null) => void;
}

const BoostedOffersTable: FC<React.PropsWithChildren<BoostedOffersTableProps>> = ({
  campaignsData,
  campaignsType,
  error,
  isLoading,
  isDeleteMode,
  sortBy,
  sortOrder,
  sortTable,
  toggleCampaignToDelete,
  setCampaignToEdit,
}) => {
  const { user } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);
  const canEditCampaign = hasWfPermissions && !isDeleteMode && campaignsType === 'future';
  const renderHeader = () =>
    tableHeaders.map((header, index) => {
      const isActive = sortBy === header.key;
      switch (header.key) {
        case 'Delete':
          return isDeleteMode ? <TableCell key={index.toString()}>Delete</TableCell> : null;
        case 'ApplicationID':
        case 'StartDate':
        case 'EndDate':
          return (
            <TableCell key={index.toString()}>
              <TableSortLabel
                active={isActive}
                direction={sortOrder}
                onClick={(): void => sortTable(header.apiAlias as BoostedOfferSortBy)}
                IconComponent={isActive ? ExpandMore : UnfoldMore}
              >
                {header.value}
              </TableSortLabel>
            </TableCell>
          );
        default:
          return <TableCell key={index.toString()}>{header.value}</TableCell>;
      }
    });

  const renderBody = () =>
    campaignsData[campaignsType].map((campaign, index) => {
      const msBeforeStart = new Date(campaign.StartDate).getTime() - Date.now();
      const startWithinTwoHrs = msBeforeStart <= 1000 * 60 * 60 * 2;
      return (
        <TableRow
          key={campaign.ID.toString()}
          onClick={canEditCampaign ? () => setCampaignToEdit(campaign) : undefined}
          style={canEditCampaign ? { cursor: 'pointer' } : undefined}
        >
          {isDeleteMode && (
            <TableCell style={{ overflow: 'visible' }}>
              {startWithinTwoHrs ? (
                <div className="tooltip-container">
                  <span className="tooltip">
                    <DisabledByDefaultRounded color="error" />
                    <span className="tooltip-text">Campaigns starting within two hours cannot be deleted</span>
                  </span>
                </div>
              ) : (
                <Checkbox
                  onChange={e => toggleCampaignToDelete(campaign, e.target.checked)}
                  disabled={startWithinTwoHrs}
                  title={startWithinTwoHrs ? 'Campaigns starting within two hours cannot be deleted' : undefined}
                />
              )}
            </TableCell>
          )}
          <TableCell>{campaign.ApplicationName}</TableCell>
          <TableCell>{campaign.ApplicationID}</TableCell>
          <TableCell>{campaign.MerchantName}</TableCell>
          <TableCell>{campaign.MerchantID}</TableCell>
          <TableCell>{formatUTCDate(campaign.StartDate, 'M/dd/yyyy H:mm zzz')}</TableCell>
          <TableCell>{formatUTCDate(campaign.EndDate, 'M/dd/yyyy H:mm zzz')}</TableCell>
          <TableCell>{campaign.Rate}</TableCell>
          <TableCell>{formatUTCDate(campaign.CreatedDate, 'M/dd/yyyy')}</TableCell>
        </TableRow>
      );
    });

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <TableContainer className="table-container">
        {error ? (
          <Paper style={{ padding: '10px' }}>
            <p>An error occurred. Please try again in a few minutes.</p>
            <p>{error instanceof Error ? error.message : error}</p>
          </Paper>
        ) : isLoading && !campaignsData[campaignsType].length ? ( // only show loading spinner if there is currently no campaigns to show, so that the table doesn't flash every time it is updated
          <Paper style={{ padding: '10px' }}>
            <Loading data-testid="loading" />
          </Paper>
        ) : !campaignsData[campaignsType].length ? (
          <Paper style={{ padding: '10px' }}>
            <p>{`There are no ${campaignsType} campaigns for the selected application(s). Try again.`}</p>
          </Paper>
        ) : (
          <Table>
            <TableHead className="table-header-group">
              <TableRow>{renderHeader()}</TableRow>
            </TableHead>
            <TableBody>{renderBody()}</TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default BoostedOffersTable;
