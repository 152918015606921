import React, { FC, useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  hasError?: boolean;
  helperText?: string;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  onStartDateChange,
  onEndDateChange,
  hasError,
  helperText,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    initialStartDate ? utcToZonedTime(initialStartDate, 'UTC') : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate ? utcToZonedTime(initialEndDate, 'UTC') : null);
  const minDate = startDate;
  const maxDate = endDate;

  useEffect(() => {
    setStartDate(initialStartDate ? utcToZonedTime(initialStartDate, 'UTC') : null);
  }, [initialStartDate]);

  useEffect(() => {
    setEndDate(initialEndDate ? utcToZonedTime(initialEndDate, 'UTC') : null);
  }, [initialEndDate]);

  const handleStartDateChange = (newValue: Date | null) => {
    if (!newValue) return;

    const utcDate = zonedTimeToUtc(newValue, 'UTC');
    setStartDate(utcDate);
    onStartDateChange(newValue);
  };

  const handleEndDateChange = (newValue: Date | null) => {
    if (!newValue) return;

    const utcDate = zonedTimeToUtc(newValue, 'UTC');
    setEndDate(utcDate);
    onEndDateChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <DatePicker
          format="yyyy-MM-dd"
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          maxDate={maxDate || undefined}
          sx={{
            width: {
              xs: '100%',
              sm: 'unset',
            },
            my: {
              xs: 1,
              sm: 0,
            },
          }}
          slotProps={{ textField: { error: hasError, helperText } }}
        />
        <DatePicker
          format="yyyy-MM-dd"
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          minDate={minDate || undefined}
          sx={{
            width: {
              xs: '100%',
              sm: 'unset',
            },
            my: {
              xs: 1,
              sm: 0,
            },
            mx: {
              sm: 1,
            },
          }}
          slotProps={{ textField: { error: hasError } }}
        />
      </div>
    </LocalizationProvider>
  );
};
