import React, { FC, useEffect, useState } from 'react';
import COLORS from 'constants/colors';
import styled from '@emotion/styled';
import _ from 'lodash';
import {
  getAppPerformanceSummariesByDate,
  getAppPerformanceSummariesGroupedByMerchant,
  getSelectedMerchantPerformanceSummary,
} from 'api/performanceSummary';
import BarChart from 'components/Charts/BarChart/BarChart';
import MultiAxisLineChart from 'components/Charts/LineChart/LineChart';
import { DoubleAxisChartData } from 'components/Charts/types';
import Loading from 'components/Loading/Loading';
import BarChartHeader from 'components/PerformanceSummary/PerformanceSummaryChart/components/BarChartHeader/BarChartHeader';
import LineChartHeader from 'components/PerformanceSummary/PerformanceSummaryChart/components/LineChartHeader/LineChartHeader.';
import { isErrorResponse } from 'helpers/typeGuards';
import { useAppDispatch, useAppSelector } from 'reduxState/hooks';
import { performanceSummaryActiveLineChartOptionSelector } from 'reduxState/store/performanceSummary/selectors';
import { setActiveLineChartOption } from 'reduxState/store/performanceSummary/slice';
import { ChartHeaderOption } from 'reduxState/store/performanceSummary/types';
import { PerformanceSummaryGroupBy } from 'reduxState/store/performanceSummary/types';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import { chartOptions } from './constants';
import { getGroupedByMerchantLabelsAndProperties, getMerchantBarChartData, getLineChartData } from './helpers';

interface PerformanceSummaryChartProps {
  appId: string;
  startDate: string;
  endDate: string;
  groupBy: PerformanceSummaryGroupBy;
  sortBy: string;
  applicationCurrency: string;
  selectedMerchantId?: number | null;
}

/**
 * A Data Representation component that will show either a DoubleAxisLineChart or DoubleAxisBarChart
 * and can be toggled based on the incoming groupBy value. The Chart is rendered using the
 * react-chartjs-2 package. When the fetched data is empty, it will NOT render the component
 *
 *
 * @param props - The properties for PerformanceSummaryChart component
 * @param props.appId - The current application Id that the user is on.
 * @param props.startDate - The start date the user selected in PerformanceSummary
 * @param props.endDate - The end date the user selected in PerformanceSummary
 * @param props.groupBy - The group by value from Performance summary. its either `day` | `month` | `merchants`
 * @param props.sortBy - Sort by value from Performance Summary, this will only affect DoubleAxisBarChart
 * @param props.applicationCurrency - Currency Type based off appId that will render Currency Values.
 * @param props.selectedMerchantId - Optional Param. Selected Merchant from Performance Summary
 *
 * @example
 * ```tsx
 * <PerformanceSummaryChart
 *  appId="0"
 *  startDate={new Date() - 7}
 *  endDate={new Date()}
 *  groupBy="merchants"
 *  sortBy="net_commission_amount"
 *  applicationCurrency="USD"
 *  selectedMerchantId={0}
 * />
 * ```
 */

const PerformanceSummaryChart: FC<PerformanceSummaryChartProps> = ({
  applicationCurrency,
  appId,
  selectedMerchantId,
  startDate,
  endDate,
  groupBy,
  sortBy,
}): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [chartData, setChartData] = useState<DoubleAxisChartData>({} as DoubleAxisChartData);
  const [barChartLegendLabels, setBarChartLegendLabels] = useState<string[]>([]);
  const [chartError, setChartError] = useState('');

  const activeLineChartOption = useAppSelector(performanceSummaryActiveLineChartOptionSelector);
  const { data: deviceData, error: deviceTokenError, isLoading: isDeviceTokenLoading } = useGetDeviceQuery(appId);
  const deviceToken = deviceData?.DeviceToken;

  // gets groupBy merchant chart data/bar graph data
  useEffect(() => {
    if (isDeviceTokenLoading) return;

    if (deviceTokenError || !deviceToken) {
      console.error(deviceTokenError || 'Failed to retrieve device token.');
      return;
    }

    const getMerchantChartData = async () => {
      if (groupBy !== 'merchants') return;
      setIsDataLoading(true);
      const allMerchantChartData = await getAppPerformanceSummariesGroupedByMerchant(appId, deviceToken, {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        sortBy: sortBy === 'merchant_id' ? 'net_commission_amount' : sortBy,
        sortOrder: 'desc',
      });

      if (isErrorResponse(allMerchantChartData)) {
        console.warn(allMerchantChartData);
        return;
      }

      if (!allMerchantChartData?.length) {
        setChartData({} as DoubleAxisChartData);
        setIsDataLoading(false);
        return;
      }

      const topMerchantPerformers = Array.isArray(allMerchantChartData) ? allMerchantChartData?.slice(0, 10) : [];
      const merchantLabelsAndProperties = getGroupedByMerchantLabelsAndProperties(sortBy);
      const newChartData = getMerchantBarChartData(topMerchantPerformers, merchantLabelsAndProperties);

      setBarChartLegendLabels(merchantLabelsAndProperties.labels);
      setChartData(newChartData);
      setIsDataLoading(false);
    };

    getMerchantChartData();
  }, [appId, deviceToken, deviceTokenError, isDeviceTokenLoading, groupBy, startDate, endDate, sortBy]);

  // gets PerformanceSummaryByDate and SelectedMerchantPerformanceSummary chart data
  useEffect(() => {
    if (isDeviceTokenLoading) return;

    if (deviceTokenError || !deviceToken) {
      console.error(deviceTokenError || 'Failed to retrieve device token.');
      return;
    }

    const getLineGraphData = async () => {
      if (groupBy === 'merchants') return;
      const params = {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        groupBy,
        sortBy: '',
        sortOrder: '',
      };

      setIsDataLoading(true);
      let response;
      if (selectedMerchantId) {
        response = await getSelectedMerchantPerformanceSummary(appId, deviceToken, {
          ...params,
          merchantId: selectedMerchantId,
        });
      } else {
        response = await getAppPerformanceSummariesByDate(appId, deviceToken, params);
      }

      if (isErrorResponse(response)) {
        console.warn(response);
        setIsDataLoading(false);
        return;
      }

      if (!response?.length) {
        setChartData({} as DoubleAxisChartData);
        setIsDataLoading(false);
        return;
      }

      try {
        const newChartData = getLineChartData({
          performanceSummaries: response,
          groupBy,
          activeChartHeaderOption: activeLineChartOption,
          selectedMerchant: !!selectedMerchantId,
        });
        setChartData(newChartData);
      } catch (error) {
        console.error('Failed to process line chart data.', error);
        setChartError('Failed to process line chart data.');
        setChartData({} as DoubleAxisChartData);
      }

      setIsDataLoading(false);
    };

    getLineGraphData();
  }, [
    setIsDataLoading,
    appId,
    deviceToken,
    deviceTokenError,
    isDeviceTokenLoading,
    activeLineChartOption,
    selectedMerchantId,
    startDate,
    endDate,
    groupBy,
  ]);

  const handleChangeChartView = (chartHeaderOption: ChartHeaderOption) => {
    dispatch(setActiveLineChartOption(chartHeaderOption));
  };

  const renderChart = () => {
    if (groupBy === 'merchants') {
      return (
        <>
          <BarChartHeader legendLabels={barChartLegendLabels} applicationCurrency={applicationCurrency} />
          <Styled.ChartContainer>
            <BarChart chartData={chartData} applicationCurrency={applicationCurrency} />
          </Styled.ChartContainer>
        </>
      );
    } else {
      return (
        <>
          <LineChartHeader
            applicationCurrency={applicationCurrency}
            chartOptions={chartOptions}
            activeChartOption={activeLineChartOption}
            handleChangeChartView={handleChangeChartView}
          />
          <Styled.ChartContainer>
            <MultiAxisLineChart data={chartData} applicationCurrency={applicationCurrency} groupBy={groupBy} />
          </Styled.ChartContainer>
        </>
      );
    }
  };

  if (chartError) {
    return <Styled.ErrorContainer>{chartError}</Styled.ErrorContainer>;
  }

  if (_.isEmpty(chartData)) {
    return <div />;
  }

  return (
    <div>
      <Styled.PerformanceSummaryChartContainer data-cy="performance-chart-container">
        {isDataLoading ? <Loading /> : renderChart()}
      </Styled.PerformanceSummaryChartContainer>
    </div>
  );
};

const Styled = {
  PerformanceSummaryChartContainer: styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    minWidth: '320px',
    height: '450px',
    marginBottom: '15px',
    borderRadius: '19px',
    backgroundColor: COLORS.white,
    boxShadow: '0px 4px 6px -1.2px rgba(173, 173, 173, 0.25)',
    '@media (max-width: 1030px)': {
      paddingBottom: '40px',
    },
  }),
  Header: styled.div({
    display: 'inherit',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '95%',
    margin: '20px 5px',
    fontFamily: `Montserrat, sans-serif`,
  }),
  ChartContainer: styled.div({
    width: '94%',
    height: '100%',
    maxHeight: '335px',
  }),
  ErrorContainer: styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '10px',
  }),
};

export default PerformanceSummaryChart;
