import React, { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { FormGroup, TextField, Button } from '@mui/material';
import Loading from 'components/Loading/Loading';
import { createTestTransactions } from 'helpers/api';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { useGetApplicationManagementQuery } from 'reduxState/store/applicationManagement/api';
import { AppOptions, AppName, AppEntityName, AppReadOnlySettings, FeedTable } from './components';
import { ProductPageUrl } from './components/ProductPageUrl';
import { CreateTestTransactionStatus } from '../../reduxState/store/applicationManagement/types';
import './scss/ApplicationManagement.scss';

interface ApplicationManagementProps {
  applicationId: string;
}

const ApplicationManagement: FC<React.PropsWithChildren<ApplicationManagementProps>> = ({ applicationId }) => {
  const [trackingCode, setTrackingCode] = useState<string>('');
  const [testTransactionStatus, setTestTransactionStatus] = useState<CreateTestTransactionStatus>('idle');

  const { user } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);

  const { data: currentApplicationManagement, isFetching } = useGetApplicationManagementQuery({ appId: applicationId });
  if (isFetching) return <Loading />;

  const applicationManagementStateCopy = JSON.parse(JSON.stringify(currentApplicationManagement));

  let messageTimer: any;

  const handleClick = async () => {
    setTrackingCode('');
    setTestTransactionStatus('idle');
    //Avoid sending a request without a tracking code
    if (trackingCode.length) {
      setTestTransactionStatus('loading');
      try {
        await createTestTransactions(Number(applicationId), trackingCode);
        setTestTransactionStatus('success');
        // Successful message goes away after 8 seconds
        messageTimer = setTimeout(() => {
          setTestTransactionStatus('idle');
        }, 8000);
      } catch (error) {
        console.error(error);
        setTestTransactionStatus('error');
        // Error message goes away after 8 seconds
        messageTimer = setTimeout(() => {
          setTestTransactionStatus('idle');
        }, 8000);
      }
    }
  };

  const renderMessages = (status: CreateTestTransactionStatus) => {
    if (status === 'loading') {
      return (
        <div className="testTransactionsLoading">
          <Loading />
          <p className="details">Adding tracking code</p>
        </div>
      );
    }
    if (status === 'success') {
      return (
        <>
          <div className="testTransactionsCreated">
            <CheckCircleIcon className="created-icon" color="success" />
            <p className="details">Tracking code added</p>
          </div>
          <span>Test transactions are being created... check back later</span>
        </>
      );
    }
    if (status === 'error') {
      return (
        <>
          <div className="testTransactionsCreated">
            <ErrorIcon className="error-icon" color="action" />
            <p className="details">Try again</p>
          </div>
          <span>There has been an error trying to create test transactions</span>
        </>
      );
    }
  };

  return (
    <div className="container">
      <div className="header">
        <h2 className="title">Application Management</h2>
      </div>
      <div className="settings">
        <FormGroup>
          <AppName
            applicationId={applicationId}
            Name={currentApplicationManagement?.Name || ''}
            applicationManagement={applicationManagementStateCopy}
          />
          <AppEntityName
            applicationId={applicationId}
            Name={applicationManagementStateCopy?.EntityName || ''}
            applicationManagement={applicationManagementStateCopy}
          />
          <ProductPageUrl applicationId={applicationId} applicationManagement={applicationManagementStateCopy} />
          <AppReadOnlySettings applicationManagement={applicationManagementStateCopy} />
          <AppOptions applicationId={applicationId} applicationManagement={applicationManagementStateCopy} />
          <FeedTable appId={applicationId} />
        </FormGroup>
      </div>
      {hasWfPermissions ? ( //Only allows WF users to add test transactions
        <div className="testTransactions">
          <TextField
            id="outlined-required"
            className="inputTestTransactions"
            placeholder="Enter TC Value"
            onChange={(event): void => {
              setTrackingCode(event.target.value);
              clearTimeout(messageTimer);
              setTestTransactionStatus('idle');
            }}
            value={trackingCode}
          />
          {testTransactionStatus === 'idle' ? (
            <Button className="btnCreateTransactions" onClick={handleClick} variant="contained" color="primary">
              Create Test Transactions
            </Button>
          ) : (
            renderMessages(testTransactionStatus)
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ApplicationManagement;
