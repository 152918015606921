import {
  AdminApplication,
  ApplicationFromApi,
  ApplicationCallback,
  GetAppsDetailsArgs,
  ClientApplication,
  NewAppGroupData,
  UpdateApplicationCallbackArgs,
} from './types';
import api from '../api/slice';
import { Split } from '../applicationManagement/types';

const applicationsApi = api.injectEndpoints({
  endpoints: builder => ({
    getApplicationDetails: builder.query<{ Splits: Split[] | null; Currency: string }, GetAppsDetailsArgs>({
      query: ({ appId, deviceToken }) => {
        return {
          url: `application/${appId}`,
          headers: {
            'X-WF-DeviceToken': deviceToken,
          },
        };
      },
      transformResponse: (response: ApplicationFromApi) => ({
        Splits: response.Policy,
        Currency: response.Currency,
      }),
    }),
    getAllAdminApplications: builder.query<AdminApplication[], void>({
      query: () => ({
        url: 'application/admin',
      }),
    }),
    getApplicationCallback: builder.query<ApplicationCallback, { appId: string }>({
      query: ({ appId }) => {
        return {
          url: `application/${appId}/callback`,
        };
      },
      providesTags: ['applicationCallback'],
    }),
    updateApplicationCallback: builder.mutation<{ RowsAffected: number }, UpdateApplicationCallbackArgs>({
      query: args => {
        return {
          url: `application/${args.appId}/callback`,
          method: 'PUT',
          body: args.body,
        };
      },
      invalidatesTags: ['applicationCallback'],
    }),
    createNewAppGroup: builder.mutation<void, { body: NewAppGroupData }>({
      query: args => {
        return {
          url: 'application/group',
          method: 'POST',
          body: args.body,
        };
      },
    }),
    createClientApplication: builder.mutation<{ applicationId: string }, { body: ClientApplication }>({
      query: args => {
        return {
          url: 'application/client',
          method: 'POST',
          body: args.body,
        };
      },
    }),
  }),
});

export const {
  useGetApplicationDetailsQuery,
  useGetAllAdminApplicationsQuery,
  useGetApplicationCallbackQuery,
  useUpdateApplicationCallbackMutation,
  useCreateNewAppGroupMutation,
  useCreateClientApplicationMutation,
} = applicationsApi;
export default applicationsApi;
