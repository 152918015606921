import {
  GetCommissionsQueryArgs,
  Commission,
  DisplayCommission,
  GetSendCommissionCallbackQueryArgs,
  CommissionHistoryDownloadData,
  GetAllCommissionHistoryDownloadsArgs,
  GetCommissionDownloadJobArgs,
  CreateCommissionDownloadArgs,
} from './types';
import { getDisplayCommissions } from './utils';
import api from '../api/slice';

const commissionApi = api.injectEndpoints({
  endpoints: builder => ({
    getCommissions: builder.query<
      { commissions: Commission[]; displayCommissions: DisplayCommission[] },
      GetCommissionsQueryArgs
    >({
      query: ({ appId, filters }) => ({
        url: `commission/${appId}`,
        params: filters,
      }),
      transformResponse: (response: { Commissions: Commission[] }) => ({
        commissions: response.Commissions,
        displayCommissions: getDisplayCommissions(response.Commissions),
      }),
    }),
    getSendCommissionCallback: builder.query<Commission, GetSendCommissionCallbackQueryArgs>({
      query: ({ appId, commissionId }) => `commission/${appId}/send-callback/${commissionId}`,
    }),
    getCommissionDownload: builder.query<any, GetCommissionDownloadJobArgs>({
      query: ({ appId, downloadId, deviceToken }) => ({
        url: `commission/${appId}/commission-download/${downloadId}`,
        headers: {
          'X-WF-DeviceToken': deviceToken || '',
        },
        responseHandler: 'text',
      }),
    }),
    getAllCommissionDownloads: builder.query<CommissionHistoryDownloadData[], GetAllCommissionHistoryDownloadsArgs>({
      query: ({ appId, userId, deviceToken }) => ({
        url: `commission/${appId}/all-commission-downloads/${userId}`,
        headers: {
          'X-WF-DeviceToken': deviceToken || '',
        },
      }),
      providesTags: ['commission downloads'],
    }),
    createCommissionDownload: builder.mutation<{ DownloadJobID: number }, CreateCommissionDownloadArgs>({
      query: ({ applicationId, deviceToken, userToken, queryString }) => {
        return {
          url: `commission/${applicationId}/commission-download`,
          method: 'POST',
          body: {
            applicationId,
            userToken,
            queryString,
          },
          headers: {
            'X-WF-DeviceToken': deviceToken || '',
          },
        };
      },
      invalidatesTags: ['commission downloads'],
    }),
  }),
});

export const {
  useGetCommissionsQuery,
  useGetSendCommissionCallbackQuery,
  useGetCommissionDownloadQuery,
  useGetAllCommissionDownloadsQuery,
  useLazyGetCommissionsQuery,
  useLazyGetSendCommissionCallbackQuery,
  useLazyGetCommissionDownloadQuery,
  useLazyGetAllCommissionDownloadsQuery,
  useCreateCommissionDownloadMutation,
} = commissionApi;
export default commissionApi;
