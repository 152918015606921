import React, { FC, useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import PaymentReport from 'components/Payment/PaymentReport/PaymentReport';
import PrintWindow from 'components/Portal/PrintWindow/PrintWindow';
import { exportJSONToCSV, downloadCSV } from 'helpers';
import { Application } from 'reduxState/store/user/types';
import { useLazyGetPaymentDetailQuery } from '../../../reduxState/store/payment/api';
import { isPaymentDetail } from '../../../reduxState/store/payment/typeGuards';
import { PaymentDetailCommission } from '../../../reduxState/store/payment/types';
import { Payment } from '../../../reduxState/store/payment/types';
import { Application as ApplicationType } from '../../../reduxState/store/user/types';
import './PaymentReportDownload.scss';

interface PaymentReportDownloadProps {
  applicationId: string;
  payment: Payment;
  applications: ApplicationType[];
}

const PaymentReportDownload: FC<React.PropsWithChildren<PaymentReportDownloadProps>> = ({
  applicationId,
  payment,
  applications,
}) => {
  const displayDate = format(new Date(payment.CreatedDate), 'MMM dd, yyyy');

  const [showReport, setShowReport] = useState(false);
  const [microsoftLoading, setMicrosoftLoading] = useState(false);
  const [csvError, setCSVError] = useState(false);
  const [getPaymentDetail] = useLazyGetPaymentDetailQuery();

  const application = applications.find((app: Application) => app.id === applicationId) || ({} as Application);

  const toggleShowReport = (): void => setShowReport(prev => !prev);

  const closePrintWindow = (): void => setShowReport(false);

  const downloadCSVClick = async (
    appName: string,
    applicationId: string,
    paymentId: number,
    isMicrosoftLoading: boolean,
  ) => {
    // Don't download the report again if it's in the middle of loading
    if (isMicrosoftLoading === true) {
      return;
    }

    setMicrosoftLoading(true);
    try {
      const { data, error } = await getPaymentDetail({ appId: applicationId, paymentId });
      if (error || !data || !isPaymentDetail(data)) {
        setCSVError(true);
        toast.error('There was an issue downloading the payment report CSV');
        return;
      }

      const columns = Object.keys(data.Commissions[0]) as Array<keyof PaymentDetailCommission>;
      const csv = exportJSONToCSV(
        data.Commissions,
        columns.map(key => ({
          accessor: key,
          // add spaces between words for correct CSV header
          name: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        })),
      );
      downloadCSV(csv, generateReportName(appName, payment.CreatedDate, payment.Processor) + '.csv', window.document);
      setMicrosoftLoading(false);
    } catch (error) {
      console.error(error);
      setCSVError(true);
    }
  };

  const isMicrosoft = applicationId === '52' || applicationId === '56';

  const generateReportName = (name: string, createdDate: string, processor: string) => {
    const downloadDate = format(new Date(createdDate), 'MM-dd-yyyy');

    let parsedProcessor: string;
    if (processor === 'CHECK') {
      parsedProcessor = 'Partner';
    } else {
      parsedProcessor = 'User';
    }
    return `Wildfire ${name} Payment Report ${downloadDate} ${parsedProcessor}`;
  };

  return (
    <div className="payment-report-download">
      {application ? (
        <>
          <div className="date">{displayDate}</div>
          {isMicrosoft && !csvError && (
            <div
              className="link"
              onClick={() => downloadCSVClick(application.name, applicationId, payment.PaymentID, microsoftLoading)}
            >
              {microsoftLoading
                ? 'Downloading CSV. One moment please'
                : generateReportName(application.name, payment.CreatedDate, payment.Processor)}
            </div>
          )}
          {isMicrosoft && csvError && <div className="link">Error Retrieving report</div>}
          {!isMicrosoft && (
            <div className="link" onClick={toggleShowReport}>
              {showReport ? 'Close' : generateReportName(application.name, payment.CreatedDate, payment.Processor)}
            </div>
          )}
          {showReport && (
            <PrintWindow
              onClose={closePrintWindow}
              windowTitle={generateReportName(application.name, payment.CreatedDate, payment.Processor)}
            >
              <PaymentReport application={application} paymentId={payment.PaymentID} displayDate={displayDate} />
            </PrintWindow>
          )}
        </>
      ) : null}
    </div>
  );
};

export default PaymentReportDownload;
