import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../reduxState/hooks';
import { featureFlagSelector } from '../../../reduxState/store/featureFlag/selectors';
import './ApplicationThumbnail.scss';

interface OwnProps {
  name: string;
  linkPath: string;
}

const ApplicationThumbnail: FC<React.PropsWithChildren<OwnProps>> = ({ name, linkPath }) => {
  const { enabled } = useAppSelector(state => featureFlagSelector(state, 'test'));
  return (
    <div className="application-thumbnail-container">
      <Link
        className="application-thumbnail-initials"
        style={enabled ? { backgroundColor: 'red' } : undefined}
        to={linkPath}
      >
        {name[0].toUpperCase()}
      </Link>
      <div className="application-thumbnail-info">
        <Link to={linkPath}>{name}</Link>
      </div>
    </div>
  );
};

export default ApplicationThumbnail;
