import React, { FC } from 'react';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import PaymentReportDownload from 'components/Payment/PaymentReportDownload/PaymentReportDownload';
import { useGetPaymentHistoryQuery } from 'reduxState/store/payment/api';
import { Application as ApplicationType } from '../../reduxState/store/user/types';
import './Payment.scss';

interface PaymentProps {
  applicationId: string;
  applications: ApplicationType[];
}

const Payment: FC<React.PropsWithChildren<PaymentProps>> = ({ applicationId, applications }) => {
  const { data: paymentHistory, isFetching, isError } = useGetPaymentHistoryQuery({ appId: applicationId });

  if (isError) {
    return <Error />;
  }

  if (isFetching || !paymentHistory) {
    return <Loading />;
  }

  return (
    <div className="payment">
      <div className="payment-header">
        <h2 className="payment-title">Payment Detail Reports</h2>
      </div>
      {paymentHistory.length
        ? paymentHistory.map(payment => (
            <PaymentReportDownload
              applications={applications}
              key={payment.PaymentID}
              applicationId={applicationId}
              payment={payment}
            />
          ))
        : 'No payments to display'}
    </div>
  );
};

export default Payment;
