import React, { FC, useState } from 'react';
import COLORS from 'constants/colors';
import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import {
  Autocomplete,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
} from '@mui/material';
import { startOfDay, endOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { debounce } from 'lodash';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'components/DateRangePickerUTC/DateRangePickerUTC';
import TextInput from 'components/TextInput/TextInput';
import { findErrorMessage } from 'helpers';
import { searchMerchants } from 'helpers/api';
import { isErrorResponse } from 'helpers/typeGuards';
import { useCreateBoostedOfferCampaignsMutation } from 'reduxState/store/boostedOffers/api';
import { useGetUserApplicationsQuery } from 'reduxState/store/user/api';
import { getAppOptions } from '../helpers';

const isValidMultiplier = (str: string) => /^\d*(\.\d+)?$/.test(str) && /\d/.test(str);

interface DropdownOption {
  label: string;
  id: string;
}

interface CampaignCreationData {
  ApplicationID: number;
  MerchantID: number;
  Rate: number;
  StartDate: string;
  EndDate: string;
  ModifiedAuthor: string;
}

interface FailedCampaignCreationData {
  ApplicationID: number;
  MerchantID: number;
  Error: string;
}

interface FormErrors {
  applications: string;
  merchants: string;
  multiplier: string;
  dates: string;
}

/**
 * CreateBoostedOfferCampaignForm is a React component that allows users to create boosted offer campaigns.
 * The component provides a form to input campaign details such as applications, merchants, multiplier,
 * start date, and end date. It also displays any failed campaign creations.
 *
 * @component
 * @example
 * return (
 *   <CreateBoostedOfferCampaignForm />
 * )
 */
const CreateBoostedOfferCampaignForm: FC = () => {
  const { user } = useAuth0();
  const { data: applicationsData } = useGetUserApplicationsQuery();
  const applications = applicationsData?.applications || [];
  const [merchantOptions, setMerchantOptions] = useState<DropdownOption[]>([]);
  const [selectedAppOptions, setSelectedAppOptions] = useState<DropdownOption[]>([]);
  const [selectedMerchantOptions, setSelectedMerchantOptions] = useState<DropdownOption[]>([]);
  const [multiplier, setMultiplier] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isMerchantsLoading, setIsMerchantsLoading] = useState(false);
  const [failedCampaignsToCreate, setFailedCampaigns] = useState<FailedCampaignCreationData[]>([]);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState<FormErrors>({
    applications: '',
    merchants: '',
    multiplier: '',
    dates: '',
  });
  const [appSearchValue, setAppSearchValue] = useState('');
  const [createBoostedOfferCampaigns, { isLoading: isCreateReqLoading }] = useCreateBoostedOfferCampaignsMutation();

  const renderFailedCampaignRow = ({ ApplicationID, MerchantID, Error }: FailedCampaignCreationData) => {
    const appName = selectedAppOptions.find(option => option.id === ApplicationID.toString())?.label;
    const merchantName = selectedMerchantOptions.find(option => option.id === MerchantID.toString())?.label;
    if (!appName || !merchantName) return <></>;
    return (
      <TableRow key={`${appName}${merchantName}`}>
        <TableCell>{appName}</TableCell>
        <TableCell>{merchantName}</TableCell>
        <TableCell>{Error}</TableCell>
      </TableRow>
    );
  };

  const clearForm = () => {
    setMerchantOptions([]);
    setSelectedAppOptions([]);
    setSelectedMerchantOptions([]);
    setMultiplier('');
    setStartDate(null);
    setEndDate(null);
    setIsMerchantsLoading(false);
    setFailedCampaigns([]);
    setError('');
    setFormErrors({
      applications: '',
      merchants: '',
      multiplier: '',
      dates: '',
    });
    setAppSearchValue('');
  };

  const handleMerchantSearch = async (query: string) => {
    if (query.length < 3) {
      return setMerchantOptions([]);
    }

    setIsMerchantsLoading(true);
    const results = await searchMerchants(query, '5');

    if (isErrorResponse(results)) {
      return setError(findErrorMessage(results));
    }

    const merchantResults = results.filter(result => result.Type === 'merchant' && !result.Disabled);
    const merchantDropdownOptions: DropdownOption[] = merchantResults.map(({ ID, Value }) => ({
      id: String(ID),
      label: Value,
    }));

    setMerchantOptions(merchantDropdownOptions);
    setIsMerchantsLoading(false);
  };

  const debouncedMerchantSearch = debounce(handleMerchantSearch, 300);

  const handleOnSubmit = async () => {
    const newFormErrors: Partial<FormErrors> = {};

    if (!multiplier || !isValidMultiplier(multiplier)) {
      newFormErrors.multiplier =
        'Invalid Multiplier provided. Multiplier must be a number, and may include a decimal value.';
    }

    if (!startDate) {
      newFormErrors.dates = 'Campaign must have a start date.';
    } else if (!endDate) {
      newFormErrors.dates = 'Campaign must have an end date.';
    } else if (!(startDate < endDate)) {
      newFormErrors.dates = 'Start Date for the campaign must be before End Date.';
    } else if (startDate < new Date(Date.now())) {
      newFormErrors.dates = 'Start Date for the campaign can not come before the day the campaign was created.';
    }

    if (!selectedAppOptions.length) {
      newFormErrors.applications = 'At least one application must be provided.';
    }

    if (!selectedMerchantOptions.length) {
      newFormErrors.merchants = 'At least one merchant must be provided.';
    }

    if (!isEqual(newFormErrors, {})) {
      return setFormErrors({ ...formErrors, ...newFormErrors });
    }

    const campaignsToCreate: CampaignCreationData[] = [];
    selectedAppOptions.forEach(app => {
      selectedMerchantOptions.forEach(merchant => {
        campaignsToCreate.push({
          ApplicationID: Number(app.id),
          MerchantID: Number(merchant.id),
          Rate: Number(multiplier),
          StartDate: zonedTimeToUtc(startOfDay(startDate!), 'UTC').toISOString(),
          EndDate: zonedTimeToUtc(endOfDay(endDate!), 'UTC').toISOString(),
          ModifiedAuthor: user!.email!,
        });
      });
    });

    setFailedCampaigns([]);
    setError('');

    try {
      await createBoostedOfferCampaigns({
        campaigns: campaignsToCreate,
      }).unwrap();
      toast.success(`Campaign${campaignsToCreate.length > 1 ? 's' : ''} created successfully!`);
      setFailedCampaigns([]);
    } catch (errorResponse) {
      let errorMessage = 'Failed to create boosted offer campaigns.';
      const error = errorResponse.data;
      if (error?.ErrorMessage) {
        errorMessage = error.ErrorMessage[0].toUpperCase() + error.ErrorMessage.slice(1);
        if (error?.Failed) {
          setFailedCampaigns(error.Failed as FailedCampaignCreationData[]);
        }
      }
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  return (
    <Styled.BoostedOfferCampaign>
      <Styled.BoostedOfferCampaignContainer>
        <Styled.TitleContainer>Create Boosted Offer Campaign</Styled.TitleContainer>
        <Styled.BodyContainer>
          <Autocomplete
            sx={{ my: 1 }}
            id="applications"
            options={getAppOptions(applications)}
            value={selectedAppOptions}
            loading={!getAppOptions(applications).length}
            multiple
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            inputValue={appSearchValue}
            onInputChange={(e, value, reason) => {
              if (reason !== 'reset') setAppSearchValue(value);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Applications"
                placeholder="Select all applications"
                error={Boolean(formErrors.applications)}
                helperText={formErrors.applications}
              />
            )}
            onChange={(e, value) => {
              setSelectedAppOptions(value);
              setFormErrors({ ...formErrors, applications: '' });
            }}
            disableCloseOnSelect
          />
          <Autocomplete
            sx={{ my: 1 }}
            multiple
            id="merchants"
            value={selectedMerchantOptions}
            options={[...selectedMerchantOptions, ...merchantOptions]}
            loading={isMerchantsLoading}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={x => x}
            renderInput={params => (
              <TextField
                {...params}
                label="Merchants"
                placeholder="Select all merchants"
                error={Boolean(formErrors.merchants)}
                helperText={formErrors.merchants}
              />
            )}
            onInputChange={(e, value) => debouncedMerchantSearch(value)}
            onChange={(e, value) => {
              setSelectedMerchantOptions(value);
              setFormErrors({ ...formErrors, merchants: '' });
            }}
          />
          <TextInput
            label="Multiplier"
            value={multiplier}
            handleChange={val => {
              setMultiplier(val);
              setFormErrors({ ...formErrors, multiplier: '' });
            }}
            hasError={Boolean(formErrors.multiplier)}
            errorText={formErrors.multiplier}
          />
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={value => {
              setStartDate(value);
              setFormErrors({ ...formErrors, dates: '' });
            }}
            onEndDateChange={value => {
              setEndDate(value);
              setFormErrors({ ...formErrors, dates: '' });
            }}
            hasError={Boolean(formErrors.dates)}
            helperText={formErrors.dates}
          />
          <Styled.Footer>
            <Styled.MessageContainer>
              {error && (
                <div>
                  <p style={{ color: 'red' }}>{error}</p>
                </div>
              )}
            </Styled.MessageContainer>
            <Styled.ClearButton onClick={clearForm}>CLEAR</Styled.ClearButton>
            <Styled.SubmitButton isLoading={isCreateReqLoading} disabled={isCreateReqLoading} onClick={handleOnSubmit}>
              {isCreateReqLoading ? 'Creating Campaign...' : 'Create Campaign'}
            </Styled.SubmitButton>
          </Styled.Footer>
          {!!failedCampaignsToCreate.length && (
            <Paper variant="outlined">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Application</TableCell>
                    <TableCell>Merchant</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {failedCampaignsToCreate.map(failedCampaign => renderFailedCampaignRow(failedCampaign))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Styled.BodyContainer>
      </Styled.BoostedOfferCampaignContainer>
    </Styled.BoostedOfferCampaign>
  );
};

export default CreateBoostedOfferCampaignForm;

const Styled = {
  BoostedOfferCampaign: styled.div({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px',
  }),
  BoostedOfferCampaignContainer: styled.div({
    maxWidth: '1400px',
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Montserrat, sans-serif;',
    boxSizing: 'border-box',
    backgroundColor: COLORS.white,
    borderRadius: '5px',
    padding: '25px',
    boxShadow: `0px 4px 6px 3px rgba(173, 173, 173, 0.25);`,
  }),
  TitleContainer: styled.h4({
    width: '100%',
    marginBottom: '10px',
  }),
  FormContainer: styled.section({
    display: 'flex',
    width: '100%',
  }),
  BodyContainer: styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  MessageContainer: styled.div({
    height: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  Footer: styled.div({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%',
    height: '80px',
    marginTop: '15px',
  }),
  ClearButton: styled((props: any) => <Button {...props} />)(() => ({
    minHeight: '50px',
    maxHeight: '50px',
    padding: '20px',
    marginRight: '10px',
    backgroundColor: COLORS.lightGrey,
    borderColor: COLORS.lightGrey,
    color: COLORS.darkGrey,
    lineHeight: '1rem',
    fontFamily: 'inherit',
    fontWeight: '600',
    ':hover': {
      backgroundColor: COLORS.darkPurple,
      color: COLORS.white,
    },
  })),
  SubmitButton: styled(({ isLoading, ...props }: any) => <Button {...props} />)(props => ({
    minHeight: '50px',
    maxHeight: '50px',
    padding: '20px',
    backgroundColor: props.isLoading ? COLORS.lightGrey2 : COLORS.lightPurple,
    borderColor: props.isLoading ? COLORS.lightPurple : undefined,
    fontColor: props.isLoading ? COLORS.lightPurple : COLORS.white,
    color: COLORS.white,
    lineHeight: '1rem',
    fontFamily: 'inherit',
    fontWeight: '600',
    ':hover': {
      backgroundColor: COLORS.darkPurple,
    },
  })),
};
