import { KINDS } from './constants';
import { Concept, ConceptKinds } from './types';

export const isConcept = (concept: Concept): concept is Concept => {
  const { ID, Kind, Value, URL } = concept;

  if ((typeof ID || typeof Kind || typeof Value || typeof URL) !== 'string') {
    return false;
  }

  return true;
};

export const isConceptKind = (value: unknown): value is ConceptKinds => {
  return KINDS.some(kind => kind === value);
};

export const isConcepts = (concepts: Concept[]): concepts is Concept[] => {
  for (const item of concepts) {
    if (!isConcept(item)) {
      return false;
    }
  }

  return true;
};
