import React, { useEffect, useState, FC, CSSProperties } from 'react';
import { DragIndicator } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { CommissionColumnVisible } from 'reduxState/store/commission/types';

interface ReorderableTableHeaderProps {
  initialColumns: CommissionColumnVisible[];
  onReorganize: (columns: CommissionColumnVisible[]) => void;
  callbackVisible: boolean;
}

const styles: Record<string, CSSProperties> = {
  columnRow: {
    display: 'flex',
    flex: 1,
    margin: '0px',
    padding: '0px',
    height: '100%',
    minHeight: '40x',
    listStyleType: 'none',
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
  },
  columnCell: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: '100%',
    minHeight: '40x',
    padding: '0',
    borderRight: '1px solid #868686',
    background: `#adacac`,
    backgroundColor: 'rgba(66, 55, 116, 85%)',
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    fontSize: '14px',
    color: 'white',
    textAlign: 'center',
    lineHeight: '14px',
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    color: '#382f64', // Dark Grey
  },
  dragDiv: {
    position: 'absolute',
    zIndex: 2,
    width: '24px',
    height: '26px',
  },
  cellText: {
    flex: 1,
    textAlign: 'center',
    zIndex: 1,
    minWidth: '120px',
    padding: '2px 10px 2px 20px',
  },
};

const DraggableTableHeader: FC<ReorderableTableHeaderProps> = ({ initialColumns, onReorganize, callbackVisible }) => {
  const [columns, setColumns] = useState<CommissionColumnVisible[]>([]);

  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    const updatedColumns: CommissionColumnVisible[] = [...columns];
    const [removed] = updatedColumns.splice(sourceIndex, 1);
    updatedColumns.splice(destinationIndex, 0, removed);

    setColumns(updatedColumns);
    onReorganize(updatedColumns);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="columns" direction="horizontal">
          {provided => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                ...styles.columnRow,
              }}
            >
              {columns.map((column, index) => (
                <Draggable key={column.name} draggableId={column.name} index={index}>
                  {provided => (
                    <li
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      style={{
                        ...styles.columnCell,
                        ...provided.draggableProps.style,
                      }}
                    >
                      {/** Added an empty Div to handle drag/drop behavior since text will have a z-index:1 */}
                      <div {...provided.dragHandleProps} style={{ ...styles.icon, ...styles.dragDiv }}></div>
                      <div style={styles.icon}>
                        <DragIndicator />
                      </div>
                      <div role="cell" style={styles.cellText}>
                        {column.name}
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {callbackVisible && (
                <li role="cell" style={{ ...styles.columnCell, justifyContent: 'center' }}>
                  Resend Callback
                </li>
              )}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DraggableTableHeader;
