export const KINDS = [
  'domain',
  'brand',
  'hotel',
  'keyword',
  'merchant',
  'movie',
  'performer',
  'phrase',
  'restaurant',
  'theater',
  'venue',
  'sport',
] as const;
