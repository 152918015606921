import React, { FC, MouseEvent, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ArrowDropDown, FileDownloadOutlined } from '@mui/icons-material';
import { Autocomplete, Menu, MenuItem, TextField } from '@mui/material';
import Button from 'components/Button/Button';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { AppOption, CampaignsType } from 'reduxState/store/boostedOffers/types';
import { useGetUserApplicationsQuery } from 'reduxState/store/user/api';
import { getAppOptions } from '../helpers';
import './BoostedOffersFilters.scss';

const CAMPAIGN_TYPE_MENU_ID = 'campaign-type-menu';

interface BoostedOffersFormProps {
  campaignsType: CampaignsType;
  isDeleteMode: boolean;
  setAppsToInclude: (apps: AppOption[]) => void;
  setCampaignsType: (campaignsType: CampaignsType) => void;
  setIsDeleteMode: (isDeleteMode: boolean) => void;
  deleteCampaigns: () => void;
  exportToCsv: () => void;
  adminAppId?: string;
}

const BoostedOffersFilters: FC<React.PropsWithChildren<BoostedOffersFormProps>> = ({
  campaignsType,
  setAppsToInclude,
  setCampaignsType,
  exportToCsv,
  adminAppId,
  isDeleteMode,
  setIsDeleteMode,
  deleteCampaigns,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [appsToIncludeField, setAppsToIncludeField] = useState<AppOption[]>([]);
  const [isExportDisabled, setIsExportDisabled] = useState<boolean>(false);
  const { user } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);

  const { data: applicationsData } = useGetUserApplicationsQuery();
  const applications = applicationsData?.applications || [];

  const viewableApplications = hasWfPermissions ? applications : applications.filter(app => app.adminId === adminAppId);

  const renderMenuItem = (types: CampaignsType[]) =>
    types.map((type, index) => (
      <MenuItem
        key={index}
        className={campaignsType === type ? 'active' : ''}
        onClick={() => handleCampaignTypeChange(type)}
      >
        {`View ${type} campaigns`}
      </MenuItem>
    ));

  const openMenu = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (): void => {
    setAnchorEl(null);
  };

  const handleCampaignTypeChange = (type: CampaignsType): void => {
    setCampaignsType(type);
    setIsDeleteMode(false);
    setAnchorEl(null);
  };

  const handleAppChange = (value: AppOption[], reason: string): void => {
    // Send a new request to get campaigns only when the option is removed or cleared
    if (reason === 'selectOption') {
      setAppsToIncludeField(value);
      setIsExportDisabled(true);
    } else {
      setAppsToIncludeField(value);
      setAppsToInclude(value);
      setIsExportDisabled(false);
    }
  };

  const handleFilter = () => {
    setAppsToInclude(appsToIncludeField);
    setIsExportDisabled(false);
  };

  return (
    <div className="filters-container">
      <div className="form-container">
        <Button
          className="button"
          variant="outlined"
          id={CAMPAIGN_TYPE_MENU_ID}
          aria-controls={CAMPAIGN_TYPE_MENU_ID}
          aria-haspopup="true"
          endIcon={<ArrowDropDown />}
          onClick={openMenu}
        >
          {`View ${campaignsType} campaigns`}
        </Button>
        <div className="applications-menu-container">
          <Autocomplete
            multiple
            disableCloseOnSelect
            size="small"
            className="applications-menu"
            options={getAppOptions(viewableApplications)}
            isOptionEqualToValue={(option, value) => option.id === value.id} // To prevent from getting a MUI warning when option and value are pointing to different instances
            onChange={(e, value, reason) => handleAppChange(value, reason)}
            sx={{
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderRadius: '6px',
                border: 'none',
              },
              '& button.MuiButtonBase-root': {
                visibility: 'visible',
              },
            }}
            renderInput={params => (
              <TextField
                {...params}
                className="applications-menu-field"
                variant="outlined"
                placeholder="Applications to Include"
              />
            )}
          />
          <Button className="button" variant="outlined" onClick={handleFilter}>
            Filter
          </Button>
          <Button
            className={`button ${isExportDisabled ? 'disabled' : ''}`}
            disabled={isExportDisabled}
            variant="outlined"
            onClick={() => exportToCsv()}
          >
            Export
            <FileDownloadOutlined sx={{ marginLeft: '5px' }} />
          </Button>
          {hasWfPermissions &&
            campaignsType === 'future' &&
            (isDeleteMode ? (
              <>
                <Button className="button grey" color="info" variant="outlined" onClick={() => setIsDeleteMode(false)}>
                  Cancel
                </Button>
                <Button className="button red" color="error" variant="outlined" onClick={deleteCampaigns}>
                  Delete
                </Button>
              </>
            ) : (
              <Button className="button" variant="outlined" onClick={() => setIsDeleteMode(true)}>
                Delete Campaigns
              </Button>
            ))}
        </div>
      </div>
      <Menu
        id={CAMPAIGN_TYPE_MENU_ID}
        className="boosted-offers-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!(anchorEl && anchorEl.id === CAMPAIGN_TYPE_MENU_ID)}
        onClose={closeMenu}
      >
        {renderMenuItem(['past', 'present', 'future'])}
      </Menu>
    </div>
  );
};

export default BoostedOffersFilters;
