import { endOfDay, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { findErrorMessage } from 'helpers';
import { request } from 'helpers/api';
import {
  BaseAppPerformanceSummaryParams,
  PerformanceSummaryByDate,
  PerformanceSummaryGroupedByMerchant,
  SelectedMerchantPerformanceSummary,
} from './types';
import { GetAppStatsByDateParams, GetSelectedMerchantStatsParams } from './types';

export const getAppPerformanceSummariesByDate = async (
  appId: string,
  deviceToken: string,
  params: GetAppStatsByDateParams,
): Promise<PerformanceSummaryByDate[]> => {
  const { groupBy, sortOrder, sortBy } = params;

  const startDate = zonedTimeToUtc(startOfDay(params.startDate), 'UTC').toISOString();
  const endDate = zonedTimeToUtc(endOfDay(params.endDate), 'UTC').toISOString();

  const baseUrl = `/api/private/v2/${appId}/stats`;
  const query = `?group_by=${groupBy}&start_date=${startDate}&end_date=${endDate}&sort_order=${sortOrder}&sort_by=${sortBy}`;

  try {
    const response = await request('GET', `${baseUrl}${query}`, deviceToken);
    const data = response.json();

    return data;
  } catch (error) {
    throw new Error(findErrorMessage(error) || 'Failed to retrieve Performance Summaries by date.');
  }
};

export const getAppPerformanceSummariesGroupedByMerchant = async (
  appId: string,
  deviceToken: string,
  params: BaseAppPerformanceSummaryParams,
): Promise<PerformanceSummaryGroupedByMerchant[]> => {
  const { sortOrder, sortBy } = params;

  const startDate = zonedTimeToUtc(startOfDay(params.startDate), 'UTC').toISOString();
  const endDate = zonedTimeToUtc(endOfDay(params.endDate), 'UTC').toISOString();

  const baseUrl = `/api/private/v2/${appId}/stats/merchant`;
  const query = `?start_date=${startDate}&end_date=${endDate}&sort_order=${sortOrder}&sort_by=${sortBy}`;

  try {
    const response = await request('GET', `${baseUrl}${query}`, deviceToken);
    const data = response.json();

    return data;
  } catch (error) {
    throw new Error(findErrorMessage(error) || 'Failed to retrieve Performance Summaries grouped by merchants.');
  }
};

export const getSelectedMerchantPerformanceSummary = async (
  appId: string,
  deviceToken: string,
  params: GetSelectedMerchantStatsParams,
): Promise<SelectedMerchantPerformanceSummary[]> => {
  const { groupBy, sortOrder, sortBy, merchantId } = params;

  const startDate = zonedTimeToUtc(startOfDay(params.startDate), 'UTC').toISOString();
  const endDate = zonedTimeToUtc(endOfDay(params.endDate), 'UTC').toISOString();

  const baseUrl = `/api/private/${appId}/merchant/${merchantId}/stats`;
  const query = `?group_by=${groupBy}&start_date=${startDate}&end_date=${endDate}&sortBy=${
    sortBy !== 'merchant_name' ? sortBy : ''
  }&sort_order=${sortOrder}`;

  try {
    const response = await request('GET', `${baseUrl}${query}`, deviceToken);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(findErrorMessage(error) || `Failed to retrieve stats for selected merchant.`);
  }
};
