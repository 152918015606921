import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import CommissionHistoryReportDownload from 'components/Commission/CommissionHistoryReportDownload/CommissionHistoryReportDownload';
import Loading from 'components/Loading/Loading';
import { useGetAllCommissionDownloadsQuery } from 'reduxState/store/commission/api';
import { CommissionHistoryDownloadData } from 'reduxState/store/commission/types';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import './CommissionHistoryDownload.scss';

interface CommissionHistoryDownloadProps {
  applicationId: string;
}

const CommissionHistoryDownload: FC<CommissionHistoryDownloadProps> = ({ applicationId }) => {
  const { user } = useAuth0();
  const { data: deviceData, error: deviceTokenError } = useGetDeviceQuery(applicationId);
  const deviceToken = deviceData?.DeviceToken;

  if (deviceTokenError || !deviceToken) {
    console.error(deviceTokenError || 'Failed to retrieve device token.');
  }

  const { data: commissionHistoryDownloads, isFetching, error } = useGetAllCommissionDownloadsQuery({
    appId: applicationId,
    deviceToken: deviceToken!,
    userId: user!.sub!,
  });

  if (error) {
    toast.error('Failed to get commission history download links.');
  }

  if (isFetching) {
    return <Loading data-testid="loading" />;
  }

  return (
    <div className="commission-download">
      <div className="commission-download-header">
        <h2 className="commission-download-title">Commission History Downloads</h2>
      </div>
      {commissionHistoryDownloads?.length
        ? commissionHistoryDownloads.map((downloadData: CommissionHistoryDownloadData, index: number) => (
            <CommissionHistoryReportDownload downloadData={downloadData} key={index} />
          ))
        : 'No downloads to display'}
    </div>
  );
};

export default CommissionHistoryDownload;
