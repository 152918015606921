import { normalizeString } from 'helpers';
import { Concept } from 'reduxState/store/concept/types';

// filters concepts based on users input [filter].
// will normalize strings [remove diacritical marks or accent makes] both for filter and concept.Value.
// For example, 'é' will be searched as 'e'
export const filterConcepts = (concepts: Concept[], filter: Concept['Value']): Concept[] => {
  if (!filter) {
    return concepts;
  }
  const normalizedFilter = normalizeString(filter);

  const filterConcept = (concept: Concept): boolean => {
    const normalizedValue = normalizeString(concept.Value);

    return normalizedValue.includes(normalizedFilter);
  };

  return concepts.filter(concept => filterConcept(concept));
};
