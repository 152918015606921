import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommissionRate from 'components/Commission/CommissionRate/CommissionRate';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import { useGetMerchantCommissionRatesQuery as getMerchantCommissionRates } from 'reduxState/store/merchant/api';
import { selectActiveDomainsById } from 'reduxState/store/merchant/selectors';
import { ParsedMerchantCommissionRate } from 'reduxState/store/merchant/types';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import { useAppSelector } from '../../../reduxState/hooks';
import MerchantLogoPlaceholder from '../MerchantLogoPlaceholder/MerchantLogoPlaceholder';

import './MerchantRateDetails.scss';

interface OwnProps {
  applicationId: string;
}

const MerchantRateDetails: FC<React.PropsWithChildren<OwnProps>> = ({ applicationId }) => {
  const { data: deviceData, error: deviceTokenError } = useGetDeviceQuery(applicationId);
  const deviceToken = deviceData?.DeviceToken;

  if (deviceTokenError || !deviceToken) {
    console.error(deviceTokenError || 'Failed to retrieve device token.');
  }

  const { merchantId } = useParams();
  const merchant = useAppSelector(selectActiveDomainsById(applicationId, merchantId || ''));

  if (!merchantId) {
    toast.error('No Merchant ID Found.');
    return (
      <div className="merchant">
        <Error errorStatusCode={404} />
      </div>
    );
  }

  const { data, error: merchantCommissionRatesError, isError, isFetching, refetch } = getMerchantCommissionRates({
    appId: applicationId,
    deviceToken: deviceToken!,
    merchantId: merchantId,
  });
  const isValidMerchantId = Boolean(parseInt(merchantId));

  const parsedMerchantCommissionRates = data?.parsedMerchantCommissionRates;

  return (
    <div className="merchant">
      {isError && (merchantCommissionRatesError || !merchant || !isValidMerchantId) && !isFetching && (
        <Error
          errorStatusCode={
            'status' in merchantCommissionRatesError ? (merchantCommissionRatesError.status as number) : 404
          }
          retry={isValidMerchantId ? () => refetch() : undefined}
        />
      )}
      {isFetching && !isError && <Loading />}
      {!isFetching && parsedMerchantCommissionRates && merchant && (
        <>
          <div className="merchant-detail">
            <MerchantLogoPlaceholder merchant={merchant ? merchant : {}} />
            <h2 className="name">{merchant ? merchant.Name : ''}</h2>
          </div>
          <div className="merchant-commission-rates">
            {parsedMerchantCommissionRates ? (
              parsedMerchantCommissionRates.length === 0 ? (
                <div className="no-rates">
                  No merchant rate details available. Please contact{' '}
                  <span className="email-support">support@wildlink.me</span> for more info.
                </div>
              ) : (
                parsedMerchantCommissionRates.map((rate: ParsedMerchantCommissionRate) => (
                  <CommissionRate key={rate.name} rate={rate} />
                ))
              )
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default MerchantRateDetails;
