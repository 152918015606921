import React, { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { findRtkErrorMessage } from 'helpers';
import {
  useGetFeaturedMerchantQuery,
  useGetMerchantsForFeaturedMerchantQuery,
  useSaveAppFeaturedMerchantEditMutation,
  useCreateAppFeaturedMerchantMutation,
  useSaveAppSortOrderMutation,
} from 'reduxState/store/featuredMerchant/api';
import {
  FeaturedMerchant,
  FeaturedMerchantEditTypes,
  FeaturedMerchantSaveTypes,
  MerchantsToSort,
  MerchantType,
  SelectedMerchants,
} from 'reduxState/store/featuredMerchant/types';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import AddOrRemoveButtons from './FeaturedMerchantButtons/AddOrRemoveButtons';
import FeaturedMerchantCategory from './FeaturedMerchantList/FeaturedMerchantCategory/FeaturedMerchantCategory';
import Merchants from './FeaturedMerchantList/Merchants/Merchants';
import Loading from '../Loading/Loading';
import './FeaturedMerchantListManager.scss';

export interface FeaturedMerchantListManagerProps {
  appId: string;
  searchValue: string;
}

const FeaturedMerchantListManager: FC<React.PropsWithChildren<FeaturedMerchantListManagerProps>> = ({
  appId,
  searchValue,
}) => {
  const initialSelectedMerchants = {
    Priority: {},
    Generic: {},
    All: {},
  };

  const initialFeaturedMerchants = {
    Priority: [],
    Generic: [],
  };

  const initialMerchantsToSort = { Priority: {}, Generic: {} };

  const [selectedMerchants, setSelectedMerchants] = useState<SelectedMerchants>(initialSelectedMerchants);
  const [featuredMerchants, setFeaturedMerchants] = useState<FeaturedMerchant>(initialFeaturedMerchants);
  const [merchantsToSort, setMerchantsToSort] = useState<MerchantsToSort>(initialMerchantsToSort);

  const { user } = useAuth0();

  const { data: deviceData, error: deviceTokenError, isFetching: isFetchingDeviceToken } = useGetDeviceQuery(appId);
  const deviceToken = deviceData?.DeviceToken as string;

  const {
    data: merchants,
    isFetching: isFetchingMerchants,
    error: merchantsError,
  } = useGetMerchantsForFeaturedMerchantQuery({ appId, searchValue });

  if (!merchants && merchantsError) console.error(merchantsError);
  if (!isFetchingDeviceToken && deviceTokenError) console.error(deviceTokenError);

  const {
    data: featuredMerchantData,
    isFetching: isFetchingFeaturedMerchants,
    error: featuredMerchantError,
  } = useGetFeaturedMerchantQuery(
    { appId, deviceToken, merchants: merchants || [] },
    { skip: !merchants || !deviceToken || typeof deviceToken !== 'string' },
  );

  if (!featuredMerchantData && featuredMerchantError) console.error(featuredMerchantError);

  const [
    saveAppFeaturedMerchantEdit,
    { isLoading: isUpdatingFeaturedMerchants },
  ] = useSaveAppFeaturedMerchantEditMutation();
  const [createAppFeaturedMerchant, { isLoading: isCreatingFeaturedMerchant }] = useCreateAppFeaturedMerchantMutation();
  const [saveAppSortOrder, { isLoading: isUpdatingAllFeaturedMerchants }] = useSaveAppSortOrderMutation();

  const saveFeaturedMerchants = async (type: FeaturedMerchantEditTypes, saveType: FeaturedMerchantSaveTypes) => {
    if (!deviceToken) return;
    const isRemoveSaveType = saveType === 'Remove';

    if (featuredMerchantError) {
      const updateType = isRemoveSaveType ? type : 'All';
      // Creates an array of editable featured merchant Ids
      const newFeaturedMerchantIdList: number[] = Array.from(
        Object.keys(selectedMerchants[updateType] || []).map(Number),
      );
      const errorMessage: string = findRtkErrorMessage(featuredMerchantError);
      // When a featured merchant is being added/removed for the very first time to an application
      // use the createAppFeaturedMerchant POST endpoint
      if (errorMessage === 'Not Found') {
        try {
          createAppFeaturedMerchant({
            appId,
            deviceToken,
            body: {
              type: isRemoveSaveType ? type : saveType,
              newFeaturedMerchantIdList,
              author: user!.email || 'Unknown user',
            },
          }).unwrap();
        } catch (error) {
          console.error(error);
        }
      }
      // After the creation of the very first featured merchant
      // use the saveAppFeaturedMerchantEdit PUT endpoint to update featured merchants
    } else {
      if (!featuredMerchantData) return;

      const currentFeaturedMerchants: MerchantType[] = isRemoveSaveType
        ? // FeaturedMerchant type also includes a boolean that is not related here
          (featuredMerchantData[type as keyof FeaturedMerchant] as MerchantType[]) || []
        : (featuredMerchantData[saveType as keyof FeaturedMerchant] as MerchantType[]) || [];

      // Creates an array of current featured merchant Ids
      const currentFeaturedMerchantIds: number[] = currentFeaturedMerchants.map(merchant => merchant.ID);
      // Creates an array of editable featured merchant Ids
      const featureMerchantIdsToEdit: number[] = Array.from(Object.keys(selectedMerchants[type] || []).map(Number));
      const currentAndUpdateIds = [...currentFeaturedMerchantIds, ...featureMerchantIdsToEdit];
      // Gets rid of duplicate values
      const uniqueAddableIds = currentAndUpdateIds.filter((id, index) => currentAndUpdateIds.indexOf(id) === index);

      const newFeaturedMerchantIdList = isRemoveSaveType
        ? currentFeaturedMerchantIds.filter(id => !featureMerchantIdsToEdit.includes(id))
        : uniqueAddableIds;

      try {
        saveAppFeaturedMerchantEdit({
          appId,
          deviceToken,
          body: {
            type: isRemoveSaveType ? type : saveType,
            newFeaturedMerchantIdList,
            author: user!.email || 'Unknown user',
          },
        }).unwrap();
      } catch (error) {
        console.error(error);
      }
    }

    setSelectedMerchants(initialSelectedMerchants);
  };

  const saveSortOrder = () => {
    if (!deviceToken) return;

    const genericFeaturedMerchants: number[] = featuredMerchants['Generic'].map(merchant => merchant.ID);
    const priorityFeaturedMerchants: number[] = featuredMerchants['Priority'].map(merchant => merchant.ID);

    try {
      saveAppSortOrder({
        appId,
        deviceToken,
        body: { priorityFeaturedMerchants, genericFeaturedMerchants, author: user!.email || 'Unknown author' },
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
    setMerchantsToSort(initialMerchantsToSort);
    setSelectedMerchants(initialSelectedMerchants);
  };

  const isLoading = () => {
    const allLoadingStates = [
      isFetchingMerchants,
      isFetchingFeaturedMerchants,
      isUpdatingFeaturedMerchants,
      isCreatingFeaturedMerchant,
      isUpdatingAllFeaturedMerchants,
    ];
    // Returns true if any of the requests are still loading or fetching
    return allLoadingStates.some(isLoading => isLoading);
  };

  // Check if there are any selected merchants waiting to be sorted
  const noMerchantsToSort = isEmpty(merchantsToSort.Priority || {}) && isEmpty(merchantsToSort.Generic || {});

  useEffect(() => {
    if (featuredMerchantData) setFeaturedMerchants(featuredMerchantData);
  }, [featuredMerchantData]);

  if (!merchants) return null;

  return (
    <div className="featured-merchant-list-manager">
      <div className="merchant-body">
        <div className="tables merchant-table">
          <Merchants
            appId={appId}
            merchants={merchants}
            selectedMerchants={selectedMerchants}
            setSelectedMerchants={setSelectedMerchants}
            isLoading={isLoading()}
          />
        </div>
        <div className="button-container">
          <AddOrRemoveButtons
            onAdd={() => saveFeaturedMerchants('All', 'Priority')}
            onRemove={() => saveFeaturedMerchants('Priority', 'Remove')}
            isLoading={isLoading()}
          />
          <AddOrRemoveButtons
            onAdd={() => saveFeaturedMerchants('All', 'Generic')}
            onRemove={() => saveFeaturedMerchants('Generic', 'Remove')}
            isLoading={isLoading()}
          />
        </div>
        <div className="tables featured-tables">
          <div className="tables priority-merchant-table">
            <FeaturedMerchantCategory
              appId={appId}
              featuredMerchants={featuredMerchants}
              featuredMerchantType={'Priority'}
              isLoading={isLoading()}
              merchantsToSort={merchantsToSort}
              searchValue={searchValue}
              selectedMerchants={selectedMerchants}
              setFeaturedMerchants={setFeaturedMerchants}
              setMerchantsToSort={setMerchantsToSort}
              setSelectedMerchants={setSelectedMerchants}
            />
          </div>
          <div className="tables generic-merchant-table">
            <FeaturedMerchantCategory
              appId={appId}
              featuredMerchants={featuredMerchants}
              featuredMerchantType={'Generic'}
              isLoading={isLoading()}
              merchantsToSort={merchantsToSort}
              searchValue={searchValue}
              selectedMerchants={selectedMerchants}
              setFeaturedMerchants={setFeaturedMerchants}
              setMerchantsToSort={setMerchantsToSort}
              setSelectedMerchants={setSelectedMerchants}
            />
          </div>
        </div>
      </div>
      <div className="merchant-footer">
        <Button variant="contained" color="primary" disabled={isLoading() || noMerchantsToSort} onClick={saveSortOrder}>
          Save
        </Button>
        {isLoading() && <Loading size={24} className="save-loading" />}
      </div>
    </div>
  );
};

export default FeaturedMerchantListManager;
