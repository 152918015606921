import { Application, AppGroup, UserApplication, Device } from './types';
import api from '../api/slice';

const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getDevice: builder.query<Device, string>({
      query: appId => ({
        url: `user/${appId}/device`,
        method: 'POST',
      }),
    }),
    getUserApplications: builder.query<{ applications: Application[]; appGroups: AppGroup[] }, void>({
      query: () => '/application',
      transformResponse: (response: UserApplication) => {
        const { appGroups, applications } = response;
        const appGroupCopy = [...appGroups];
        const applicationsCopy = [...applications];
        const sortedApplications = applicationsCopy?.sort((a, b) => a.name.localeCompare(b.name));
        const sortedAppGroups = appGroupCopy?.sort((a, b) => a.adminName.localeCompare(b.adminName));

        return {
          appGroups: sortedAppGroups,
          applications: sortedApplications,
        };
      },
    }),
  }),
});

export const { useGetDeviceQuery, useGetUserApplicationsQuery } = userApi;
export default userApi;
