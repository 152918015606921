import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialCommissionColumns } from './constants';
import { CommissionColumn, CommissionState, SetCommissionColumnsPayload } from './types';

const initializeCommissionColumnsState = (): CommissionColumn[] => {
  const allColumnsCopy = JSON.parse(JSON.stringify(initialCommissionColumns));
  const localStorageColumns = localStorage.getItem('commission_history_columns_state');

  if (localStorageColumns) {
    const savedCommissionColumns: CommissionColumn[] = JSON.parse(localStorageColumns);

    // resets column visibility when there are new/removed columns
    if (savedCommissionColumns.length !== allColumnsCopy.length) {
      return allColumnsCopy;
    }

    const allColumnsSet = new Set(allColumnsCopy.map((column: CommissionColumn) => column.accessor));
    const filteredCommissionColumns: CommissionColumn[] = [];

    savedCommissionColumns.forEach((column: CommissionColumn) => {
      if (allColumnsSet.has(column.accessor)) {
        filteredCommissionColumns.push(column);
      }
    });
    return filteredCommissionColumns;
  }

  return allColumnsCopy;
};

export const initialCommissionState: CommissionState = {
  columns: initializeCommissionColumnsState(),
};

const commissionSlice = createSlice({
  name: 'commission',
  initialState: initialCommissionState,
  reducers: {
    setCommissionColumns(state: CommissionState, action: PayloadAction<SetCommissionColumnsPayload>) {
      state.columns = action.payload.columns;
      localStorage.setItem('commission_history_columns_state', JSON.stringify(action.payload.columns));
    },
  },
});

export const { setCommissionColumns } = commissionSlice.actions;

export const commissionReducer = commissionSlice.reducer;
