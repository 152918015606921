import React, { FC, useState } from 'react';
import { Delete, ForwardToInbox, DoneOutline } from '@mui/icons-material';
import { TableCell, TableRow, Button, Tooltip } from '@mui/material';
import { UserWithRoles, requestChangePasswordEmail } from 'api/user';
import Loading from 'components/Loading/Loading';
import { USERS_IDENTITIES_MAP } from 'helpers/auth0';
import { useGetDeviceQuery } from 'reduxState/store/user/api';

interface OwnProps {
  appId: string;
  user: UserWithRoles;
  isDeleting: boolean;
  onRemoveUser: (user: UserWithRoles) => Promise<void>;
}

const UserRow: FC<React.PropsWithChildren<OwnProps>> = ({ appId, user, isDeleting, onRemoveUser }) => {
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [emailJustSent, setEmailJustSent] = useState(false);

  const { data: deviceData, error: deviceTokenError } = useGetDeviceQuery(appId);
  const deviceToken = deviceData?.DeviceToken;

  const onResetPassword = async (email: string): Promise<void> => {
    if (deviceTokenError || !deviceToken) {
      console.error(deviceTokenError || 'Failed to retrieve device token.');
      return;
    }

    try {
      setIsSendingEmail(true);
      await requestChangePasswordEmail(appId, deviceToken, email);
      setIsSendingEmail(false);
      // the following lines set the icon for the resend password email button to a checkmark for one second after a successful request
      setEmailJustSent(true);
      setTimeout(() => setEmailJustSent(false), 1000);
    } catch (err) {
      setIsSendingEmail(false);
      setEmailJustSent(false);
      console.error(err);
    }
  };

  return (
    <TableRow key={user.user_id}>
      <TableCell component="th" scope="row">
        {user.email}
      </TableCell>
      <TableCell>{user.identities.map(item => USERS_IDENTITIES_MAP[item.connection] || item).join(', ')}</TableCell>
      <TableCell>
        {user.roles
          .map(val => val.name)
          .find(name => {
            return name === 'owner' || name === 'user';
          })}
      </TableCell>
      <TableCell>
        <Button disabled={isDeleting} onClick={() => onRemoveUser(user)}>
          {isDeleting ? <Loading size={20} /> : <Delete />}
        </Button>
      </TableCell>
      <TableCell>
        <Tooltip title={emailJustSent ? 'Email sent!' : 'Send Reset Password Email'} placement="right" arrow={true}>
          <Button disabled={isSendingEmail} onClick={() => onResetPassword(user.email)}>
            {isSendingEmail && <Loading size={20} />}
            {emailJustSent && <DoneOutline />}
            {!isSendingEmail && !emailJustSent && <ForwardToInbox />}
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
