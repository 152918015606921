import React, { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Edit, Undo } from '@mui/icons-material';
import { Checkbox, CheckboxProps, FormControlLabel, InputAdornment, TextField, Tooltip } from '@mui/material';
import { useUpdateApplicationManagementMutation } from 'reduxState/store/applicationManagement/api';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import { ConfirmEdit } from './ConfirmEdit';
import { ApplicationManagement } from '../../../reduxState/store/applicationManagement/types';
import '../scss/AppOptions.scss';

export interface AppOptionsProps {
  applicationId: string;
  applicationManagement: ApplicationManagement | null;
}

interface OptionsCheckboxesProps {
  options: ApplicationManagement['Options'];
  handleChange: (option: keyof ApplicationManagement['Options']) => void;
  isDisabled: boolean;
}

const OptionsCheckboxes = React.forwardRef<HTMLDivElement, OptionsCheckboxesProps & CheckboxProps>((props, ref) => (
  <div className="options-checkboxes" ref={ref}>
    <FormControlLabel
      control={
        <Checkbox
          checked={!!props.options?.IsBrowserExtension}
          onChange={() => props.handleChange('IsBrowserExtension')}
          disabled={props.isDisabled}
        />
      }
      label="Browser Extension"
      className="settings-toggle"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={!!props.options?.IsCashbackApp}
          onChange={() => props.handleChange('IsCashbackApp')}
          disabled={props.isDisabled}
        />
      }
      label="Cashback App"
      className="settings-toggle"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={!!props.options?.IsFMTCDisabled}
          onChange={() => props.handleChange('IsFMTCDisabled')}
          disabled={props.isDisabled}
        />
      }
      label="FMTC Disabled"
      className="settings-toggle"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={!!props.options?.RequiresITPCompliance}
          onChange={() => props.handleChange('RequiresITPCompliance')}
          disabled={props.isDisabled}
        />
      }
      label="Requires ITP Compliance"
      className="settings-toggle"
    />
  </div>
));

export const AppOptions: FC<React.PropsWithChildren<AppOptionsProps>> = ({ applicationId, applicationManagement }) => {
  const { user } = useAuth0();
  const [isDisabled, setDisabled] = useState(true);
  const [updateApplicationManagement] = useUpdateApplicationManagementMutation();

  const { data: deviceData } = useGetDeviceQuery(applicationId);
  const deviceToken = deviceData?.DeviceToken;

  const [options, setOptions] = useState<ApplicationManagement['Options']>({
    IsBrowserExtension: false,
    IsCashbackApp: false,
    IsFMTCDisabled: false,
    RequiresITPCompliance: false,
  });

  useEffect(() => {
    if (applicationManagement?.Options) setOptions(applicationManagement?.Options);
  }, [applicationManagement]);

  const saveApplicationOptions = (): void => {
    if (!applicationManagement?.Options || !deviceToken) {
      return;
    }
    applicationManagement.Options = options;
    updateApplicationManagement({
      appId: applicationId,
      applicationManagement,
      author: user!.email!,
      deviceToken,
    });
  };

  const toggleDisabled = (): void => setDisabled(prevState => !prevState);

  const handleChange = (option: keyof ApplicationManagement['Options']): void => {
    const newOptions = {
      ...options,
      [option]: !options[option],
    };
    setOptions(newOptions);
  };

  const handleSave = (): void => {
    saveApplicationOptions();
    setDisabled(true);
  };

  const cancelEdit = (): void => {
    if (applicationManagement?.Options) setOptions(applicationManagement?.Options);
    setDisabled(true);
  };

  return (
    <div className="application-options settings-form-group">
      <TextField
        id="applicationOptions"
        className={`options-textfield ${!isDisabled ? 'user-editing' : ''}`}
        variant="outlined"
        label="Options"
        multiline
        disabled={isDisabled}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {!isDisabled ? (
                <Tooltip title="Cancel editing">
                  <Undo onClick={cancelEdit} className="edit-icon" data-testid="undo-icon" />
                </Tooltip>
              ) : (
                <Edit onClick={toggleDisabled} className="edit-icon" data-testid="edit-icon" />
              )}
            </InputAdornment>
          ),
          inputComponent: OptionsCheckboxes as any,
          inputProps: { options, handleChange, isDisabled },
        }}
      />
      {!isDisabled && (
        <ConfirmEdit
          headerText={'Toggle Options'}
          confirmText={`Confirm save to update application options`}
          changeText={''}
          onSave={handleSave}
        />
      )}
    </div>
  );
};
