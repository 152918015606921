import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reduxState';
import { CommissionState } from './types';

export const selectCommissionState = (state: RootState): CommissionState => state.commission;

export const commissionColumnsSelector = createSelector(selectCommissionState, commissionState => {
  const localStorageColumnString = localStorage.getItem('commission_history_columns_state');
  if (localStorageColumnString) {
    return JSON.parse(localStorageColumnString);
  }

  const commissionStateColumns = commissionState.columns;
  return commissionStateColumns || [];
});
