import {
  AddConceptsArgs,
  ApplicationNetworkPublisher,
  Concept,
  GetConceptsArgs,
  RemoveConceptsArgs,
  SyncConceptsArgs,
} from './types';
import api from '../api/slice';

const conceptsApi = api.injectEndpoints({
  endpoints: builder => ({
    getConcepts: builder.query<{ concepts: Concept[] }, GetConceptsArgs>({
      query: args => {
        return {
          url: `concepts/${args.appId}`,
          params: {
            kind: args.kind,
            addable: args.addable || 0,
          },
          headers: {
            'X-WF-DeviceToken': args?.deviceToken || '',
          },
        };
      },
      providesTags: ['concepts'],
      transformResponse: (response: { Concepts: Concept[]; NextCursor: string | null; PrevCursor: string | null }) => ({
        concepts: response.Concepts,
      }),
    }),
    getIsAllowList: builder.query<boolean, string>({
      query: appId => `application-network-publisher/${appId}`,
      transformResponse: (response: ApplicationNetworkPublisher[]) => response[0].IsAllowList,
    }),
    addConcepts: builder.mutation<void, AddConceptsArgs>({
      query: args => {
        return {
          url: `concepts/${args.appId}`,
          method: 'POST',
          body: args.body,
          headers: {
            'X-WF-DeviceToken': args.deviceToken,
          },
        };
      },
      invalidatesTags: ['concepts'],
    }),
    removeConcepts: builder.mutation<void, RemoveConceptsArgs>({
      query: args => {
        return {
          url: `concepts/${args.appId}`,
          method: 'DELETE',
          body: args.body,
          headers: {
            'X-WF-DeviceToken': args.deviceToken,
          },
        };
      },
      invalidatesTags: ['concepts'],
    }),
    syncConcepts: builder.mutation<void, SyncConceptsArgs>({
      query: args => {
        return {
          url: `concepts/sync/${args.appId}`,
          method: 'PUT',
          body: args.body,
          headers: {
            'X-WF-DeviceToken': args.deviceToken,
          },
        };
      },
      invalidatesTags: ['concepts'],
    }),
  }),
});

export const {
  useGetConceptsQuery,
  useAddConceptsMutation,
  useGetIsAllowListQuery,
  useRemoveConceptsMutation,
  useSyncConceptsMutation,
} = conceptsApi;
export default conceptsApi;
